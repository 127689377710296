import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Colaborador } from 'app/main/models/colaborador';
import { Evaluador } from 'app/main/models/evaluador';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import { ConfigGeneralServices } from '../../etapa1/configuracion/configgeneral.service';
import { ReporteIndiService } from '../reporte-indi/reporte-indi.service';


@Component({
  selector: 'app-reporte-area',
  templateUrl: './reporte-area.component.html',
  styleUrls: ['./reporte-area.component.scss']
})
export class ReporteAreaComponent implements OnInit {

  urlImage = environment.urlImages;
  public ColumnMode = ColumnMode;
  contentHeader: object;
  formBuscar:FormGroup;
  urlEvaluacion: string;
  nombreArea: string;
  rows:Colaborador[] = [];
  evaluadores:Evaluador[];
  reporte = true;
  evaluadoresAgregar:Colaborador[];
  nombreColaborador:string;
  cargandoReporte = false;
  evaluadorDesaprobado:Evaluador;
  posiblesEvaluadores:Colaborador[];
  notFoundEvaluadores = false;
  isLoadingPage = false;
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  colaborador:Colaborador;
  searchValue = '';
  tempData = [];
  rechazando = false;
  statusAprobado = 'Aprobado';
  statusRechazado = 'Rechazado';
  attributes360:any;
  agregandoEvaluador = false;
  enableRechazar = true;
  dato: any;
  data: any;
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private formBuilder:FormBuilder,
    private alert:SweetAlertService,private colaboradoresReporte:ReporteIndiService,
    private _configuracionService:ConfigGeneralServices, private router:Router) { }

  ngOnInit(): void {
    this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
    this.getContentHeader();
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] != "Etapa 3"){
          if (this.payload == "Administradores") {
            console.log("Entro");
            this.initContentHeaderAndRows();
          }else{
            this.alert.alertNotification('info', 'La etapa 3 estará activa desde ' + data['fechas']['inicioEtapa3'] + ' hasta ' + data['fechas']['finEtapa3'], 5000);
            this.router.navigate(['evaluadores']);
          }
        }else{
          this.initContentHeaderAndRows();
        }
      }
    },err => {
      console.log(err);
    })
    this.formBuscar = this.formBuilder.group({
      nombre: ['', [Validators.nullValidator]]
    })
  }

  getContentHeader(){
    this.evaluadoresAgregar = [];
    this.contentHeader = {
      headerTitle: 'Reportes por Area',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Reportes',
            isLink: false,
            link: '/'
          },
        ]
      }
    }
  }

  initContentHeaderAndRows(){
    this.getContentHeader();
    this.data = {goalOverview: {series: [0],}}
    this.colaboradoresReporte.obtenerAreas().subscribe(data => {
      if(data != null){
        this.rows = data['datos'];  
        this.tempData = this.rows;
        this.isLoadingPage = true;
        if(data[0]['porcentaje'] <= 100)
          this.data = {goalOverview: {series: [data[0]['porcentaje']]}}
      }
    },err => {
      console.log(err);
      this.isLoadingPage = false;
    })
  }

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    console.log(filterAllData)
    
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
      return (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    this.rows = filterAllData;
    return filterAllData;
  }

  verArea(area:Colaborador){
    this.nombreArea = area.nombre
  }

  verReporte(colaborador:Colaborador){
    this.cargandoReporte = true;
    this.isLoadingPage = false;
    this.colaborador = colaborador;
  
  }

  reporteArea(area:Colaborador){
    this.nombreArea = area.nombre
    this.urlEvaluacion = '/verreportearea/'+String(this.nombreArea);   
    this.router.navigate([this.urlEvaluacion]);
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }
}