
<div class="content-wrapper container-xxl p-0" *ngIf="!showEvaluacion">
    <div class="content-body"*ngIf="!showMotivo">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section class="invoice-list-wrapper">
            <div class="card"  >
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <div class="d-flex align-items-center">
                                <label class="d-flex align-items-center">Ver&nbsp;
                                    <select class="form-control mx-20" [(ngModel)]="selectedOption" (ngModelChange)="changePageSize($event)">
                                        <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}
                                        </option>
                                    </select>
                                </label>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12"></div>
                    <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end align-items-center">
                        <div class="row pb-md-0 align-items-center w-100">
                            <div class="col-sm-12 col-md-6 pr-0">
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0">
                                    <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control" placeholder="Búsqueda" (keyup)="filterAll()"/>
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-6 ml-md-0 my-sm-1 ml-sm-1 my-1 ml-1 mr-0 pr-0 pr-sm-1">
                                <fieldset class="form-group mb-0 mr-sm-0 mr-md-1 mr-0 pr-0">
                                    <ng-select
                                      [items]="selectStatus"
                                      [(ngModel)]="selectedStatus"
                                      bindLabel="name"
                                      placeholder="Seleccione estado"
                                      (change)="filterAll()"
                                    ></ng-select>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                
                <ngx-datatable #table [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap" [limit]="selectedOption"
                [columnMode]="ColumnMode.force" [headerHeight]="120" [footerHeight]="50" [scrollbarH]="true" [messages]="{emptyMessage: ''}" >
                <ngx-datatable-column name="Evaluación" prop="evaluacion" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <button (click)="goToEvaluacion(row?.ID_evaluado,row?.ID_evaluador,row?.nombreEvaluado,row?.estadoEvaluacion,row?.rolEvaluacion)" type="button" class="btn btn-primary" rippleEffect>Iniciar</button>
                        <!-- <button (click)="resetearEvaluacion(row?.ID_evaluado,row?.ID_evaluador,row?.nombreEvaluado)" type="button" class="btn btn-danger" rippleEffect>Resetear</button> -->
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Progreso" prop="estadoEvaluacion" [width]="125">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="badge badge-glow" [ngClass]="{'badge-danger': row?.estadoEvaluacion == 'Pendiente','badge-warning': row?.estadoEvaluacion == 'En Proceso', 'badge-success': row?.estadoEvaluacion == 'Finalizado'}">
                            {{row?.estadoEvaluacion}}
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Colaboladores" prop="nom_evaluado" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="d-flex">
                            <div class="avatar mr-75">
                                <img (click)="goToEvaluacion(row?.ID_evaluado,row?.ID_evaluador,row?.nombreEvaluado,row?.estadoEvaluacion,row?.rolEvaluacion)" src="{{urlImage}}{{row.ID_evaluado}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                    class="" width="42" height="42" data-placement="left"/>
                            </div>
                            <div class="columnColaborador">
                                <a href="javascript:void(0)" (click)="goToEvaluacion(row?.ID_evaluado,row?.ID_evaluador,row?.nombreEvaluado,row?.estadoEvaluacion,row?.rolEvaluacion)">{{row?.nombreEvaluado}}</a>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Relación" prop="relacion_trabajo" [width]="180">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row?.relacionEvaluado | uppercase}}
                    </ng-template>
                </ngx-datatable-column>
                
                <ngx-datatable-column name="Cargo" prop="cargo_Evaluador" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="columnCargo">
                            {{row?.cargoEvaluado | uppercase}}
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Área" prop="area" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="columnArea">
                            {{row?.areaEvaluado}}
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Inhabilitar" prop="" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="custom-control custom-switch custom-switch-warning">
                            <input (click)=desabilitarEvaluador(row?.ID_evaluado,row?.nombreEvaluado,row?.relacionEvaluado,row?.estadoEvaluacion) type="checkbox" class="custom-control-input" id={{row?.ID_evaluado}} checked />
                            <label class="custom-control-label" for={{row?.ID_evaluado}}>
                              <span class="switch-icon-left"><i data-feather="check"></i></span>
                              <span class="switch-icon-right"><i data-feather="x"></i></span>
                            </label>
                          </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                      let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                      <div style="padding: 5px 10px" *ngIf="rowCount > 0 ">
                        Total de registros {{rowCount}}
                      </div>                         
                      <div style="padding: 5px 10px" *ngIf="rowCount == 0 && isLoadingPage">
                        Cargando información...
                      </div>
                      <div style="padding: 5px 10px" *ngIf="rows.length == 0 && !isLoadingPage">
                        No hay evaluados ...
                      </div>
                      <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'" [count]="rowCount" [page]="curPage" [size]="pageSize"
                        (change)="onChange($event)" [hidden]="!((rowCount / pageSize) > 1)">
                      </datatable-pager>
                    </ng-template>
                </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>


    <div class="content-body"*ngIf="showMotivo">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-7">
                <h1 class="mt-2 mb-4 text-center">Motivo</h1>
                <div class="card">
                    <div class="card-body">
                        <div class="media mb-2">
                            <div class="avatar mr-75">
                                <img src="{{urlImage}}{{idEvaluado}}.jpg" class="rounded" width="42" height="42"
                                    data-placement="left" />
                            </div>
                            <div class="media-body my-auto">
                                <h6 class="mb-0">{{nombreEvaluado}}</h6>
                                <p class="mb-0 text-secondary">{{relacionTrabajo}}</p>
                            </div>
                        </div>
                        <form [formGroup]="formDesaprobar">
                            <label for="textAreaMotivo">Motivo</label>
                            <fieldset class="form-group">
                                <textarea  (change)="doTextareaValueChange($event)" class="motivo form-control" formControlName="motivo" id="textAreaMotivo" rows="3"
                                    placeholder="En este campo explica el motivo por el cual desaprobaste a este evaluador"
                                    ></textarea>
                                
                            </fieldset>
                        </form>
                    </div>
                </div>
                <button  class="btn btn-warning" 
                    (click)="guardarCambio()">Guardar</button>
                
                <button class="btn btn-danger mx-1" (click)="cancelarDesaprobar()">Cancelar</button>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="showEvaluacion">
        <div class="col-sm-4">
          <div class="card" style="background-color: rgb(219, 69, 69);">
            <div class="{{leftView}}"> 
              <h5 style="margin-left: 70px; margin-right:10px; margin-top: 200px;" class="nombreTitulo" >EVALUACIÓN DE DESEMPEÑO 360º</h5>
              <p style="margin-left: 70px; margin-right:10px; margin-top: 50px; margin-bottom: 100px;" class="descripcion">¡Un proceso que nos prepara para nuevos desafíos!</p>

            </div>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="card">
            <div class="card-bodyright">
              <div class="card-header">

                <h5 class="tituloEvaluacion" >Evaluación Desempeño 360º</h5>
              </div>
              
              <form #myForm="ngForm" (submit)="templateForm(myForm.value)"  class="formPregunta" >
                <h6 style=" margin-top: 10px; margin-bottom: 10px;" class="tipoUsuario">Recuerda:</h6>
                <ul class="list-style-circle">
                 <li> <h6 style="margin-right: 20px;">La información aquí consignada es de carácter confidencial, contribuye al mejoramiento y desarrollo de los colaboradores y de la organización.</h6></li>
                 <li> <h6 style="margin-right: 20px;">Al momento de realizar la evaluación debes tener presente el tipo de relación (rol) asignado con respecto a la persona evaluada. Por ejemplo cliente, colaborador, par, líder o autoevaluación.</h6></li>
                 <li> <h6 style="margin-right: 20px;">Cuando diligencies la evaluación, piensa en los comportamientos que has observado en el último año. Luego selecciona el comportamiento que identifique cada conducta en esa persona.</h6></li>
                 <li>  <h6 style="margin-right: 20px;">El proceso de Evaluación de Desempeño 360° implica una gran responsabilidad y seriedad por su impacto en el proceso de mejoramiento de cada colaborador.</h6> </li>
                 <li> <h6>Debes diligenciar completamente cada evaluación.</h6> </li>
                 <li> <h6>TEN EN CUENTA, TODAS TUS RESPUESTAS DESPUÉS DE SER GUARDADAS NO PODRÁN SER MODIFICADAS.</h6> </li>
                 

                </ul>
                
                
                <div class="botones" >
                  <!--<button class="btn btn-warning" >GuardarProceso</button>-->    
                  <button class="btn btn-warning" type="submit"  (click)="iniciarEvaluacion()">Comenzar</button>
                </div>
  
              </form> 
            
            </div>
          </div>
        </div>
      </div>


