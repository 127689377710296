<div class="content-wrapper container-xxl p-0">
  <div class="content-body" id="htmlData">
    <!-- Content-Header Component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="col-sm-12 col-md-24 d-flex align-items-center justify-content-center" *ngIf="cargandoReporte">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="q" *ngIf="content">
      <div class="col-md-24" id="pag1">
        <!--Datos funcionario-->
        <section>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header" style="justify-content: center;">
                  <div class="row">
                    <div>
                      <span class="card-title" style="font-size: 30px; margin-bottom: 5px;">Reporte
                        Individual</span><br>
                      <h6 class="font-weight-bolder" style="margin-left: 25%;">Datos del Colaborador</h6>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 col-6">
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr>
                              <th style="border: 0;">Nombre del Colaborador</th>
                              <th style="border: 0;">
                                <h6>{{nombre}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Cédula</th>
                              <th style="border: 0;">
                                <h6>{{cedula}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Cargo del Colaborador</th>
                              <th style="border: 0;">
                                <h6>{{cargof}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Área</th>
                              <th style="border: 0;">
                                <h6>{{area}}</h6>
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>

                    </div>
                    <div class="col-xl-6 col-6">
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr>
                              <th style="border: 0;">Nombre del Líder</th>
                              <th style="border: 0;">
                                <h6>{{nombreJefe}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Cargo del Líder</th>
                              <th style="border: 0;">
                                <h6>{{cargolider}}</h6>
                              </th>
                            </tr>
                            <tr>
                              <th style="border: 0;">Período</th>
                              <th style="border: 0;">
                                <h6>{{periodo}}</h6>
                              </th>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Datos funcionario End-->
        <!--Titulo-->
        <section>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header" style="justify-content: center;">
                  <div class="row">
                    <div>
                      <h3 class="font-weight-bolder">Resumen de competencias</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Titulo End-->

        <!-- Tabla 1-->
        <section id="bar-and-hori-bar-charts">
          <div class="row">
            <div class="col-xl-12 col-24">
              <div class="card">
                <div class="row" id="table-contextual">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <div class="header-left">
                          <h4 class="card-title font-weight-bolder">Total Evaluado: {{totalEvaluacion}}</h4>
                        </div>
                        <!-- <div class="header-right">
                          <h4 class="card-title font-weight-bolder">Resultado 2021: {{totalEvaluacionAnterior}} &nbsp;&nbsp;&nbsp;&nbsp;</h4>
                        </div> -->
                      </div>

                      <div class="card-body">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Competencia</th>
                                <th>Calificación</th>
                                <th>Peso</th>
                                <th>Puntaje ponderado</th>
                                <th>Promedio Organización</th>
                                <!-- <th>Resultado 2021</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default text-center">
                                <td>{{this.lista[0].nombreCompetencia}}</td>
                                <td >{{this.lista[0].calificacion}}</td>
                                <td >{{this.lista[0].peso}}</td>
                                <td >{{this.lista[0].puntajePonderado}}</td>
                                <td >{{this.lista[0].promedioOrganizacion}} </td>
                                <!-- <td >{{this.lista[0].anteriorEvaluacion}}</td> -->
                              </tr>
                              <tr class="table-default text-center">
                                <td>{{this.lista[1].nombreCompetencia}}</td>
                                <td>{{this.lista[1].calificacion}}</td>
                                <td>{{this.lista[1].peso}}</td>
                                <td>{{this.lista[1].puntajePonderado}}</td>
                                <td>{{this.lista[1].promedioOrganizacion}} </td>
                                <!-- <td>{{this.lista[1].anteriorEvaluacion}}</td> -->
                              </tr>
                              <tr class="table-default text-center">
                                <td>{{this.lista[2].nombreCompetencia}}</td>
                                <td>{{this.lista[2].calificacion}}</td>
                                <td>{{this.lista[2].peso}}</td>
                                <td>{{this.lista[2].puntajePonderado}}</td>
                                <td>{{this.lista[2].promedioOrganizacion}} </td>
                                <!-- <td>{{this.lista[2].anteriorEvaluacion}}</td> -->
                              </tr>
                              <tr class="table-default text-center">
                                <td>{{this.lista[3].nombreCompetencia}}</td>
                                <td>{{this.lista[3].calificacion}}</td>
                                <td>{{this.lista[3].peso}}</td>
                                <td>{{this.lista[3].puntajePonderado}}</td>
                                <td>{{this.lista[3].promedioOrganizacion}} </td>
                                <!-- <td>{{this.lista[3].anteriorEvaluacion}}</td> -->
                              </tr>
                              <tr class="table-default text-center" *ngIf="showDiv">
                                <td>{{this.lista[4].nombreCompetencia}}</td>
                                <td>{{this.lista[4].calificacion}}</td>
                                <td>{{this.lista[4].peso}}</td>
                                <td>{{this.lista[4].puntajePonderado}}</td>
                                <td>{{this.lista[4].promedioOrganizacion}} </td>
                                <!-- <td>{{this.lista[4].anteriorEvaluacion}}</td> -->
                              </tr>
                              <tr class="table-default text-center">
                                <td>{{this.lista[5].nombreCompetencia}}</td>
                                <td>{{this.lista[5].calificacion}}</td>
                                <td>{{this.lista[5].peso}}</td>
                                <td>{{this.lista[5].puntajePonderado}}</td>
                                <td>{{this.lista[5].promedioOrganizacion}} </td>
                                <!-- <td>{{this.lista[5].anteriorEvaluacion}}</td> -->
                              </tr>
                              <tr class="table-default text-center">
                                <td>{{this.lista[6].nombreCompetencia}}</td>
                                <td>{{this.lista[6].calificacion}}</td>
                                <td>{{this.lista[6].peso}}</td>
                                <td>{{this.lista[6].puntajePonderado}}</td>
                                <td>{{this.lista[6].promedioOrganizacion}} </td>
                                <!-- <td>{{this.lista[6].anteriorEvaluacion}}</td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Tabla 1 End -->

          </div>
        </section>
        <!-- Bar Chart Start -->
        <section id="competencia 1">
          <div class="row">

            <div class="col-xl-12 col-24">
              <div class="card">
                <div
                  class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="header-left">
                    <h4 class="card-title">Competencias</h4>
                  </div>

                </div>
                <div class="card-body">
                  <div id="chartjs-bar-chart">
                    <canvas id="barChartCanva" baseChart #barChartRef height="400" [datasets]="barChart.datasets"
                      [labels]="barChart.labels" [options]="barChart.options" [legend]="barChart.legend"
                      [chartType]="barChart.chartType" [plugins]="plugins">
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Bar Chart End -->
          </div>
        </section>
      </div>

      <!--Titulo2-->
      <section>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header" style="justify-content: center;">
                <div class="row">
                  <div>
                    <h3 class="font-weight-bolder">Resumen detallado por competencias</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--Titulo2 End-->
      
      <div class="col-md-24" id="pag2">
        <!--dSuperior-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!--Transaccion extra-->
                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Desempeño Superior</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.DesemSuperiortotalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Transaccion extra-->

                <!--Compromiso-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Autoevaluacion.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Autoevaluacion.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Lider.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Lider.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Par.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Par.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Colaborador.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Colaborador.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Cliente.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].Cliente.ponderadoCapacidad2}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Compromiso-->

                <!--Resolución de obstáculos-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Autoevaluacion.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Autoevaluacion.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Lider.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Lider.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Par.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Par.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Colaborador.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Colaborador.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Cliente.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].Cliente.ponderadoCapacidad3}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart3.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fin Resolución de obstáculos-->
              </div>
            </div>
          </div>
        </section>
        <!--dSuperior-->
      </div>

      <div class="col-md-24" id="pag3">
        <!--Flexibilidad al Cambio-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!--Capacidad Anticiparce-->
                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Flexibilidad al Cambio</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.FlexibilidadtotalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].totalpregunta}}
                    </h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart4.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Capacidad Anticiparce-->

                <!--Apertura Mental-->
                <div class="card-header d-flex pt-0 justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].totalpregunta}}
                    </h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Autoevaluacion.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Autoevaluacion.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Lider.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Lider.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Par.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Par.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Colaborador.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Colaborador.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Cliente.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].Cliente.ponderadoCapacidad2}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart5.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Apertura Mental-->

                <!--Capacidad para el Cambio-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].totalpregunta}}
                    </h3>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Autoevaluacion.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Autoevaluacion.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Lider.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Lider.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Par.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Par.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Colaborador.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Colaborador.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Cliente.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].Cliente.ponderadoCapacidad3}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart6.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fin Capacidad para el Cambio-->
              </div>
            </div>
          </div>
        </section>
        <!--Flexibilidad al Cambio-->
      </div>

      <div class="col-md-24" id="pag4">
        <!--Innovación-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!--Creatividad-->
                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Innovación</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.InnovaciontotalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart7.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Creatividad-->

                <!--Orientacion-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Autoevaluacion.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Autoevaluacion.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Lider.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Lider.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Par.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Par.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Colaborador.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Colaborador.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Cliente.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].Cliente.ponderadoCapacidad2}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart8.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Orientacion-->

                <!--Capacidad para la transformación-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Autoevaluacion.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Autoevaluacion.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Lider.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Lider.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Par.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Par.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Colaborador.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Colaborador.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Cliente.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].Cliente.ponderadoCapacidad3}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart9.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fin Capacidad para la transformación-->
              </div>
            </div>
          </div>
        </section>
        <!--Innovación-->
      </div>

      <div class="col-md-24" id="pag5">
        <!--Corresponsabilidad-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!--Espiritu de equipo-->
                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Corresponsabilidad</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.CorresponsabilidadtotalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart10.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Espiritu de equipo-->

                <!--Cooperacion-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Autoevaluacion.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Autoevaluacion.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Lider.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Lider.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Par.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Par.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Colaborador.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Colaborador.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Cliente.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].Cliente.ponderadoCapacidad2}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart11.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Cooperacion-->

                <!--Trabajo colaborativo-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].totalpregunta}}</h3>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Autoevaluacion.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Autoevaluacion.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Lider.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Lider.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Par.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Par.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Colaborador.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Colaborador.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Cliente.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].Cliente.ponderadoCapacidad3}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart12.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fin Trabajo colaborativo-->
              </div>
            </div>
          </div>
        </section>
        <!--Corresponsabilidad-->
      </div>

      <div class="col-md-24" id="pag6" *ngIf="showDiv">
        <!--Liderazgo-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!--Influencia-->
                <div class="card-header pb-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Liderazgo Transformador</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.LiderazgototalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].totalpregunta}}</h3>
                  </div>
                </div>
                
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart13.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Influencia-->

                <!--Reconocimiento-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Autoevaluacion.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Autoevaluacion.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Lider.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Lider.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Par.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Par.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Colaborador.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Colaborador.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Cliente.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].Cliente.ponderadoCapacidad2}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart14.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Reconocimiento-->

                <!--Feedback-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje
                      ={{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Autoevaluacion.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Autoevaluacion.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Lider.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Lider.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Par.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Par.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Colaborador.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Colaborador.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Cliente.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].Cliente.ponderadoCapacidad3}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart15.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fin Feedback-->
              </div>
            </div>
          </div>
        </section>
        <!--Liderazgo-->
      </div>

      <div class="col-md-24" id="pag7">
        <!--Agilidad-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!--Capacidad priorizar-->
                <div class="card-header pb-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Agilidad Organizacional</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.agilidadtotalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart16.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Capacidad priorizar-->

                <!--Capacidad respuesta-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Autoevaluacion.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Autoevaluacion.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Lider.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Lider.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Par.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Par.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Colaborador.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Colaborador.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Cliente.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].Cliente.ponderadoCapacidad2}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart17.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Capacidad respuesta-->

                <!--Capacidad desicion-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Autoevaluacion.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Autoevaluacion.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Lider.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Lider.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Par.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Par.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Colaborador.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Colaborador.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Cliente.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].Cliente.ponderadoCapacidad3}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart18.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fin Capacidad desicion-->
              </div>
            </div>
          </div>
        </section>
        <!--Agilidad-->
      </div>
      <div class="col-md-24" id="pag8">
        <!--Agilidad-->
        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!--Capacidad priorizar-->
                <div class="card-header pb-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Desempeño del Cargo</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.DCargototalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart19.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Capacidad priorizar-->

                <!--Capacidad respuesta-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Autoevaluacion.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Autoevaluacion.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Lider.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Lider.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Par.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Par.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Colaborador.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Colaborador.ponderadoCapacidad2}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Cliente.Capacidad2}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].Cliente.ponderadoCapacidad2}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart20.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 my-1" style="height: 1px; background-color: rgb(45, 44, 44);"></div>
                </div>
                <!--Fin Capacidad respuesta-->

                <!--Capacidad desicion-->
                <div class="card-header pt-0 d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-12 col-12 mt-1">
                    <div class="header-left">
                      <br>
                      <h6>
                        <h6 class="card-subtitle  mb-25 font-weight-bolder">{{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].preguntaCompetencia}}</h6>
                      </h6>
                    </div>
                  </div> 
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].totalpregunta}}
                    </h3>
                  </div>
                </div>
                
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Autoevaluacion.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Autoevaluacion.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Lider.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Lider.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Par.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Par.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Colaborador.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Colaborador.ponderadoCapacidad3}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Cliente.Capacidad3}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].Cliente.ponderadoCapacidad3}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart21.series" [chart]="{
                                  height: 350,
                                  width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                  type: 'donut'
                                }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Fin Capacidad desicion-->
              </div>
            </div>
          </div>
        </section>
        <!--Agilidad-->
      </div>

      <!-- <div class="col-md-24" id="pag8">

        <section id="apex-radar-and-donut-charts1">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title">Desempeño del Cargo</h3>
                    </div>
                  </div>
                  <div class="col-xl-6 col-6">
                    <div class="header-left">
                      <h3 class="card-title" style="text-align: right;">Total Competencia=
                        {{this.totalCompetenciasEva.totalcompetencia.DCargototalCompetencia}}</h3>
                    </div>
                  </div>
                  <div class="col-xl-12 col-12 mt-1" style="height: 0.5px; background-color: rgb(45, 44, 44);"></div>
                  <div class="col-12 mt-1">
                    <h6 class="font-weight-normal" style="margin-top: 5px;">A. Muy por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">B. Por encima de lo esperado.</h6>
                    <h6 class="font-weight-normal">C. Cumple con lo esperado.</h6>
                    <h6 class="font-weight-normal">D. Por debajo de lo esperado.</h6>
                    <h6 class="font-weight-normal">E. Muy por debajo de lo esperado.</h6>
                  </div>
                  <div class="col-12 mt-1">
                    <h3 class="card-title">Puntaje =
                      {{this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].totalpregunta}}</h3>
                  </div>
                </div>
                
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-12">
                      <div id="table-contextual">
                        <div class="table-responsive" style="margin-top: 30px;">
                          <table class="table">
                            <thead>
                              <tr style="text-align: center;">
                                <th>Evaluadores</th>
                                <th>Muestra</th>
                                <th>Puntaje</th>
                                <th>Peso</th>
                                <th>Ponderado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="table-default">
                                <td>
                                  Autoevaluación
                                </td>
                                <td> 1</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Autoevaluacion.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Autoevaluacion.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Autoevaluacion.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Líder
                                </td>
                                <td> {{this.datos[0].totalLider}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Lider.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Lider.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Lider.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Par
                                </td>
                                <td> {{this.datos[0].totalPar}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Par.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Par.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Par.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Colaborador
                                </td>
                                <td> {{this.datos[0].totalColaborador}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Colaborador.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Colaborador.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Colaborador.ponderadoCapacidad1}}
                                </td>
                              </tr>
                              <tr class="table-default">
                                <td>
                                  Cliente
                                </td>
                                <td> {{this.datos[0].totalCliente}}</td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Cliente.Capacidad1}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Cliente.PesoEvaluador}}
                                </td>
                                <td>
                                  {{this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.Cliente.ponderadoCapacidad1}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div id="donut-chart" #apexDonutChartRef>
                        <h4 class="card-title mb-75">Número de evaluadores</h4>
                        <apx-chart [series]="apexDonutChart19.series" [chart]="{
                                height: 350,
                                width: isMenuToggled === false ? apexDonutChartRef.offsetWidth : apexDonutChart.chart.width,
                                type: 'donut'
                              }" [labels]="apexDonutChart.labels" [plotOptions]="apexDonutChart.plotOptions"
                          [responsive]="apexDonutChart.responsive" [colors]="apexDonutChart.colors"
                          [legend]="apexDonutChart.legend"></apx-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div> -->

      <div class="card-footer">
        <div class="a" *ngIf="descarga">
          <button type="button" class="btn btn-primary" (click)="pruPdf()">Descargar </button>
        </div>
        <div class="b" *ngIf="descargado">
          <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Descargando...
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Calificacion Actual & Anterior-->
<div class="results" *ngIf="content">
  <div class="row py-1">
    <div class="col-5 text-center p-0">
      <h3 class="font-weight-bold m-0">Calificación:</h3>
    </div>
    <!-- <div class="col-5 text-center p-0">
      <h5 class="font-weight-bold m-0">Calificación 2021</h5>
    </div> -->
    <div class="col-5 text-center p-0">
      <h3 class="m-0 text-success">{{totalEvaluacion}}</h3>
    </div>
    <!-- <div class="col-5 text-center p-0">
      <h5 class="m-0 text-success">{{totalEvaluacionAnterior}}</h5>
    </div> -->
  </div>
</div>