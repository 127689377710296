<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section class="invoice-list-wrapper">
            <div class="card">
                <div class="row">
                    <div class="col-sm-12 col-md-6 pr-0"><br> 


                    </div>
                    <div class="col-md-11 col-12 d-flex justify-content-start justify-content-md-end align-items-center">
                        <div class="row pb-md-0 align-items-center w-100">
                            <div class="col-sm-12 col-md-6 pr-0">
                                <fieldset class="form-group mb-0 mr-sm-0 mr-md-0 mr-0 pr-0" *ngIf="payload == 'Administradores' || payload == 'Jefes'">
                                    <ng-select [items]="areas" [(ngModel)]="selectedArea" bindLabel="name"
                                        placeholder="Seleccione una Área" (change)="filterAll2()" [clearable]="false"></ng-select>
                                </fieldset>
                            </div> 
                            <div class="col-sm-12 col-md-6 pr-0">
                                <fieldset class="form-group mb-0 mr-sm-0 mr-md-0 mr-0 pr-0" *ngIf="payload == 'Administradores' || payload == 'Jefes'">
                                    <ng-select [items]="filterCargos" [(ngModel)]="selectedCargo" bindLabel="name"
                                        placeholder="Seleccione una Cargo (Opcional)" (change)="filterAll()"></ng-select>
                                </fieldset>
                            </div> 
                            <!-- <div class="col-sm-12 col-md-3 pr-0">
                                <button type="button" disabled title="No hay resultados para la descarga." (click)="showMessageNotRows()" class="btn btn-primary form-control" rippleEffect *ngIf="rows.length == 0">
                                    Descargar
                                    <span [data-feather]="'download'" [class]="'mr-25'"></span>
                                </button>
                                <button type="button" class="btn btn-primary form-control" rippleEffect (click)="crearNovedadDescargar()" *ngIf="!creandoNovedad && rows.length > 0">
                                    Descargar
                                    <span [data-feather]="'download'" [class]="'mr-25'"></span>
                                </button>
                                <button class="btn btn-primary form-control" type="button" rippleEffect *ngIf="creandoNovedad && rows.length > 0">
                                    <span class="ml-25 align-middle">Descargando</span>
                                    <span class="spinner-border spinner-border-sm" role="status" style="margin-left: 5px;" aria-hidden="true"></span>
                                </button>
                            </div>-->
                            <!-- <div class="col-sm-12 col-md-3 ml-md-0 my-sm-1 ml-sm-1 my-1 ml-1 mr-0 pr-0 pr-sm-1">
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0">
                                    <input [(ngModel)]="searchValue" name="searchValue" type="search"
                                        class="form-control" placeholder="Búsqueda" (keyup)="filterAll()" />
                                </label>
                            </div>  -->
                        </div>
                    </div>
                </div>
                
<!-- 
                <ngx-datatable [rows]="rows"  [rowHeight]="'auto'" class="bootstrap core-bootstrap" [limit]="selectedOption"
                    [columnMode]="ColumnMode.force" [headerHeight]="200" [footerHeight]="50" [scrollbarH]="true"
                    [messages]="{emptyMessage: ''}" >
                    <ngx-datatable-column name="Nombre Colaborador" prop="nombre" [width]="220">
                        <ng-template let-row="row" ngx-datatable-cell-template >
                            <div class="nombre">
                                {{row?.nombre}}
                            </div> 
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Cargo" prop="cargo" [width]="170">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="cargo">
                                {{row?.cargo }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Calificación Total" prop="calificacion" [width]="170">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="calificacion">
                                {{row?.calificacion}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Desempeño Superior" prop="desemSuperior" [width]="190">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="desemSuperior">
                                {{row?.desemSuperior}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Flexibilidad al Cambio" prop="flexibilidad" [width]="220">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="flexibilidad">
                                {{row?.flexibilidad}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Innovación" prop="innovacion" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="innovacion">
                                {{row?.innovacion}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Corresponsabilidad" prop="corresponsabilidad" [width]="160">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="corresponsabilidad">
                                {{row?.corresponsabilidad}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Liderazgo Transformador" prop="liderazgo" [width]="240">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="liderazgo">
                                {{row?.liderazgo}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Agilidad Organizacional" prop="agilidad" [width]="220">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="agilidad">
                                {{row?.agilidad}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Desempeño Cargo" prop="desemCargo" [width]="180">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="desemCargo">
                                {{row?.desemCargo}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                     jhvyuv<ngx-datatable-column class="calificacionAnterior" name="Calificacion Anterior" prop="calificacionAnterior" [width]="240">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="calificacionAnterior">
                                {{row?.calificacionAnterior}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column> hyug

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                            <div style="padding: 5px 10px" *ngIf="rowCount > 0 ">
                                Total de registros {{rowCount}}
                            </div>
                            <div style="padding: 5px 10px" *ngIf="rowCount == 0 && !isLoadingPage">
                                No hay registros ...
                            </div>
                            <div style="padding: 5px 10px" *ngIf="rowCount == 0 && isLoadingPage">
                                Cargando información...
                            </div>
                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                [count]="rowCount" [page]="curPage" [size]="pageSize" (change)="onChange($event)"
                                [hidden]="!((rowCount / pageSize) > 1)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>-->
           
            <div style="text-align:center">
                <apx-chart
                [annotations]="chartOptions.annotations"
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [yaxis]="chartOptions.yaxis"
                [grid]="chartOptions.grid"
                [stroke]="chartOptions.stroke"
                [title]="chartOptions.title"
                [markers]="chartOptions.markers"
                [tooltip]="chartOptions.tooltip"
                [fill]="chartOptions.fill"
                
              ></apx-chart>
                <!-- <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [colors]="chartOptions.colors"
    [grid]="chartOptions.grid"
    [markers]="chartOptions.markers"
    [annotations]="chartOptions.annotations"
    [fill]="chartOptions.fill"
  ></apx-chart> -->
</div> 
<!-- <div id="chart">
    <apx-chart
      [annotations]="chartOptions2.annotations"
      [series]="chartOptions2.series"
      [chart]="chartOptions2.chart"
      [xaxis]="chartOptions2.xaxis"
      [yaxis]="chartOptions2.yaxis"
      [colors]="chartOptions2.colors"
      [grid]="chartOptions2.grid"
      [markers]="chartOptions2.markers"
      [fill]="chartOptions2.fill"
      
    ></apx-chart>
  </div> -->

              </div>
        </section>
    </div>
</div>