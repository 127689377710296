import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { AuthenticationService } from 'app/auth/service';
import { GeneralResponse } from 'app/main/models/generalResponse';
import { ResultadosResponse } from 'app/main/models/resultadosResponse';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import { NovedadesService } from '../../etapa1/colaboradores/novedades.service';
import { ConfigGeneralServices } from '../../etapa1/configuracion/configgeneral.service';
import { ResultadosService } from './nine-box-talent.service';
import {
  ChartComponent,
  ApexAnnotations,
  ApexTitleSubtitle,
  ApexStroke,
  ApexDataLabels,
  ApexAxisChartSeries,
  ApexChart,
  ApexYAxis,
  ApexXAxis,
  ApexFill,
  ApexGrid,
  ApexMarkers,
  ApexTooltip
} from "ng-apexcharts";
import { transferKeyToLowerCase } from '@aws-amplify/core';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  fill: ApexFill;
  colors: string[];
  labels: string[];
  dataLabels: ApexDataLabels;
  legend: any; //ApexLegend;
  stroke: ApexStroke;
  markers: ApexMarkers;
  annotations:ApexAnnotations;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
};



@Component({
  selector: 'app-resultados',
  templateUrl: './nine-box-talent.component.html',
  styleUrls: ['./nine-box-talent.component.scss']
})


export class NineBoxTalentComponent implements OnInit {
  public generalReponse:GeneralResponse;
  public contentHeader: object;
  public generalResponse:GeneralResponse;
  public limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  public selectedOption: number = 15;
  public searchValue = '';
  public rows: ResultadosResponse[] = [];
  public tempData = this.rows;
  public creandoNovedad:boolean = false;
  public isLoadingPage:boolean = false;
  public preferredUsername = this._authService.getAttributes()['preferred_username'];
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
  public areas:any[] =[];
  public cargos:any[] =[];
  public filterCargos:any[] =[];
  public selectedArea = null;
  public selectedCargo = null;
  public url = environment.apiResults;
  public chartOptions: Partial<ChartOptions>;
  


  public ColumnMode = ColumnMode;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private _novedadesService:NovedadesService, private _authService:AuthenticationService,
              private alert:SweetAlertService, private router:Router, private _resultadosService:ResultadosService, private _configuracionService:ConfigGeneralServices) {
                               
                 this.chartOptions = { 
                annotations: {
                  position: "back",
                   yaxis: [
                    
                    {
                      y: 0,
                      y2: 3.01,
                      borderColor: "#000",
                      fillColor: "#FF0000",
                      opacity: 0.05,
                    },
                      {
                        y: 3.01,
                        y2: 4.00,
                        borderColor: "#000",
                        fillColor: "#FFFF00",
                        opacity:0.05,
                      },
                      {
                        y: 4.00,
                        y2: 5,
                        borderColor: "#000",
                        fillColor: "#00FFFF",
                        opacity: 0.05,
                      }
                  ], 
                   xaxis: [
                    {
                      x: 0,
                      x2: 3.16,
                      fillColor: "#FF0000",
                      opacity: 0.05,
                    },
                    {
                      x: 3.16,
                      x2: 4.07,
                      fillColor: "#FFFF00",
                      opacity: 0.05,
                    },
                    {
                      x: 4.07,
                      x2: 5,
                      fillColor: "#008f39",
                      opacity: 0.05,
                    }
                  ], 
                  
                }, 
                  series: [
                    {
                      name: "",
                      data: [
                      ]
                    },
                  ],
                  chart: {
                    id: "chart1",
                    height: 600,
                    type: "scatter",
                     animations: {
                      enabled: true
                    },
                    zoom: {
                      enabled: true,
                      //autoScaleYaxis:true,
                      type: 'xy'
                    },
                    toolbar: {
                      show: true,
                      autoSelected:"zoom",
                      
                      tools:{
                        download:false,
                        zoom:true,
                        zoomin:false,
                        zoomout:false,
                        pan:false
                      }

                    }, 
                    
                  
                    
                  },
                   
                 /*  tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                      console.log(w)
                      console.log(series)
                      console.log(w.config.series[seriesIndex].data[0])
                      return (
                        '<div class="custom-tooltip">' +
                        '<div class="group">' +
                        "<div><span>Desempeño del Cargo</span> <br />" +
                        w.config.series[seriesIndex].name +
                        "</div>" +
                        '<div class="group1">Calificacion Competencias: ' +
                        series[seriesIndex][dataPointIndex] +
                        "</div>" +
                        "</div>" +
                        "</div>"
                      );
                    }
                  }, */
                  xaxis: {
                    
                    title:{
                      text:"Calificación Competencias",
                      offsetY: -17
                    },
                    tickAmount: 3,
                    //floating:true,
                    min: 2.25,
                    max: 5,
                    labels: {
                      show:false,
                      formatter: function(val) {
                        return parseFloat(val).toFixed(1)
                      }
                    }
                  },
                
                  
                  yaxis: {
                    tickAmount: 3,
                    min: 2.01,
                    max: 5,
                    title:{
                      text:"Desempeño del Cargo",
                      offsetX:15,
                    },
                    labels: {
                      show:false,
                    }
                  },
                  markers: {
                    size: 20,
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                    offsetX: -10,

                     
                  }
                };
 

               }

  ngOnInit(): void {
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] === "Etapa 1"){
          if(this.payload == "Administradores"){
            this.initContentHeaderAndResultados();
          }else{
            this.alert.alertNotification('info', 'La etapa 3 estará activa desde ' + data['fechas']['inicioEtapa3'] + ' hasta ' + data['fechas']['finEtapa3'], 5000);
            this.router.navigate(['evaluadores']);
          }
        }else{
          this.initContentHeaderAndResultados();
        }
      }
    },err => {
      console.log(err);
    })    
  }

  initContentHeaderAndResultados(){
    this.getContentHeader();
    this.getResultados();
    
    
  }

  getContentHeader(){
    this.contentHeader = {
      headerTitle: 'Nine Box Talent',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Listado',
            isLink: false,
          },
        ]
      }
    }
  }

  crearNovedadDescargar(){
    this.creandoNovedad = true;
    let request:any = this.buildRequestDescargar();
    console.log(request);
    this._novedadesService.sendNovedad(request).subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      if(this.generalResponse.result.estado != 'Exitoso'){
        this.creandoNovedad = false;
        this.alert.alertNotification('error', 'Error al crear la novedad para la descarga del archivo', 5000);
        return;
      }
      this.alert.alertNotification('success', 'Se a creado una novedad para la descarga del archivo con el id: ' + this.generalResponse.payload['novedades'][0]['idProceso'], 7000);
      this.router.navigate(['/novedades']);
      this.creandoNovedad = false;
    },err => {
      console.error(err);
      this.creandoNovedad = false;
      this.alert.alertNotification('error', 'Error al crear la novedad para la descarga del archivo', 5000);
    })
  }

  buildRequestDescargar():any {
    let url:string = this.urlJefeOrAdministrador();
    if(this.selectedArea != null) {
      let request: any = {
        "payload": {
          "novedades": [
            {
              "idColaborador": Number(this.preferredUsername),
              "proceso": "RP",
              "tipo": "Resultado Evaluación 360",
              "url": url,
              "area": this.selectedArea
            }
          ]
        }
      }
      return request;
    }

    let request: any = {
      "payload": {
        "novedades": [
          {
            "idColaborador": Number(this.preferredUsername),
            "proceso": "RP",
            "tipo": "Resultado Evaluación 360",
            "url": url,
          }
        ]
      }
    }
 
    return request;
  }

  urlJefeOrAdministrador():string{
    if(this.payload == 'Jefes') {
      this.url += '/lider/resultados?idColaborador='+this.preferredUsername;
    }else if(this.payload == 'Administradores') {
      this.url += '/admin/nineboxtalent';
    }
    return this.url;
  }

  getResultados(){
    this.isLoadingPage = true;
    this._resultadosService.getResultados(this.payload, this.preferredUsername).subscribe(data => {
      this.generalReponse = data as GeneralResponse;
      this.areas = this.generalReponse.payload['areas'];
      this.cargos = this.generalReponse.payload['cargos'];
      this.rows = this.generalReponse.payload['colaboradores'];
      this.tempData = this.rows;
      this.isLoadingPage = false;
      this.selectedArea=this.areas[0]
      this.filterAll2();
      this.updateSeries();
    },err => {
      console.error(err);
      this.isLoadingPage = false;
      this.alert.alertNotification('error', 'Error al cargar los resultados.', 5000);
    })
  }

  filterAll2() {
    //console.log(this.selectedArea);
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;

    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
        return (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1 ||
                d.cargo && d.cargo.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }


    let filterArea = this.selectedArea ? this.selectedArea : this.areas[0];
    this.selectedArea=filterArea
    if (filterArea != null) filterArea = filterArea;

    filterAllData = filterAllData.filter(row => {
      return (row.area === filterArea) || filterArea == null;
    });

    this.rows = filterAllData;
    this.updateSeries();
    let arraysCargos=[]
    this.rows.forEach( (element) => {
      console.log('Cargo')
      arraysCargos.push(element.cargo)
    });  

    const removeDups = (arr: any[]): any[] => {
      return arr.filter((item,
          index) => arr.indexOf(item) === index);
  }
  this.filterCargos=removeDups(arraysCargos)
    

    return filterAllData;
  }

  filterAll() {
    console.log(this.selectedCargo);
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;

    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
        return (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1 ||
                d.cargo && d.cargo.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterCargo = this.selectedCargo ? this.selectedCargo : null;
    if (filterCargo != null) filterCargo = filterCargo;
    
    filterAllData = filterAllData.filter(row => {
      return (row.cargo === filterCargo && row.area === this.selectedArea);
    });

    if(filterAllData.length==0)
      this.filterAll2()
    else{
    this.rows = filterAllData;
    this.updateSeries();
        }
            return filterAllData;
  }

  showMessageNotRows(){
    this.alert.alertNotification('info', 'No hay resultados para la descarga.', 5000);
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }


  updateSeries() {
    console.log(this.tempData[0].nombre)
    var datos=[];
    var dataArray=[]; 
    var images=[];

    this.rows.forEach( (element) => {
      dataArray.push([element.desemCargo, element.calificacion])

      datos.push({
        name: element.nombre,
        data: dataArray
      })
      images.push(environment.urlImages+element.idColaborador+".jpg")
      dataArray=[]; 
    });    
    this.chartOptions.series =  datos
    this.chartOptions.fill= {
      type: "image",
      opacity: 1,
      image: {
        src: images,
        width: 40,
        height: 40,
        
      }
    }  
  }

  


}