import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Colaborador } from 'app/main/models/colaborador';
import { Evaluador } from 'app/main/models/evaluador';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import { ReporteIndiService } from './reporte-indi.service';
import { ConfigGeneralServices } from '../../etapa1/configuracion/configgeneral.service';
import { RegistroEvaluadoresService } from '../../etapa1/registro-evaluadores/registro-evaluadores.service';
import { RevisarPostulacionesService } from '../../etapa1/revisar-postulaciones/revisar-postulaciones/revisar-postulaciones.service';

@Component({
  selector: 'app-reporte-indi',
  templateUrl: './reporte-indi.component.html',
  styleUrls: ['./reporte-indi.component.scss']
})
export class ReporteIndiComponent implements OnInit {

  urlImage = environment.urlImages;
  public ColumnMode = ColumnMode;
  contentHeader: object;
  formBuscar:FormGroup;
  urlEvaluacion: string;

  rows:Colaborador[] = [];
  evaluadores:Evaluador[];
  reporte = true;
  evaluadoresAgregar:Colaborador[];
  nombreColaborador:string;
  idUsuario: number;
  idusuario: number ;

  cargandoReporte = false;
  data: any;

  evaluadorDesaprobado:Evaluador;
  posiblesEvaluadores:Colaborador[];
  notFoundEvaluadores = false;
  isLoadingPage = false;
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  colaborador:Colaborador;
  searchValue = '';
  tempData = [];
  rechazando = false;
  statusAprobado = 'Aprobado';
  statusRechazado = 'Rechazado';
  attributes360:any;
  attributes3602:any;
  agregandoEvaluador = false;
  enableRechazar = true;
  rolUser: String[];
  nameRol: any;
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private formBuilder:FormBuilder, private revisarPostulacionesService:RevisarPostulacionesService,
    private alert:SweetAlertService, private registroEvaluadoresService: RegistroEvaluadoresService,
    private _configuracionService:ConfigGeneralServices, private router:Router, private colaboradoresReporte:ReporteIndiService) { }

  ngOnInit(): void {
    this.data = {goalOverview: {series: [0]}}
    this.isLoadingPage = true;
    this.attributes360 = JSON.parse(localStorage.getItem('payload'));
    this.attributes3602 = JSON.parse(localStorage.getItem('attributes360'));
    this.idusuario = this.attributes3602['preferred_username'];
    this.rolUser = this.attributes360['cognito:groups']
    if(this.rolUser[0] === "Administradores"){
      this.nameRol = "Administrador"
    } else {
      this.nameRol = "Jefe"
    }

    let datos: any={
      ID: this.idusuario,
      Rol: this.nameRol
    }

    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] != "Etapa 3"){
          if(this.payload == "Administradores"){
            this.initContentHeaderAndColaboradores();
          }else{
            this.alert.alertNotification('info', 'La etapa 3 estará activa desde ' + data['fechas']['inicioEtapa3'] + ' hasta ' + data['fechas']['finEtapa3'], 5000);
            this.router.navigate(['evaluadores']);
          }
        }else{
          this.initContentHeaderAndColaboradores();
        }
      }
    },err => {
      console.log(err);
    })  
    this.formBuscar = this.formBuilder.group({
      nombre: ['', [Validators.nullValidator]]
    })  
  }

  getContentHeader(){
    this.evaluadoresAgregar = [];
    this.contentHeader = {
      headerTitle: 'Reportes Individuales',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Reportes',
            isLink: false,
            link: '/'
          },
        ]
      }
    }
  }

  initContentHeaderAndColaboradores(){
    let datos: any={
      ID: this.idusuario,
      Rol: this.nameRol
    }
    this.getContentHeader();
    this.data = {goalOverview: {series: [0],}}
    this.colaboradoresReporte.obtenerColaboradores(datos).subscribe(data => {
      if(data != null){
        this.rows = data['datos'];        
        this.tempData = this.rows;
        this.isLoadingPage = false;
        if(data[1]['porcentaje'] <= 100)
          this.data = {goalOverview: {series: [data[1]['porcentaje']]}}
      }
    },err => {
      console.log(err);
      this.isLoadingPage = false;
    })       
  }

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    console.log(filterAllData)
    
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
      return (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    this.rows = filterAllData;
    return filterAllData;
  }

  verEvaluadores(colaborador:Colaborador){
    this.evaluadores = [];
    this.colaborador = colaborador;
    this.revisarPostulacionesService.getEvaluadoresPorIdUsuario(colaborador.ID_colaborador, 10).subscribe(data => {
      if(data['datos'].length > 0){
        this.evaluadores = data['datos'];
        this.nombreColaborador = colaborador.nombre;
        this.idUsuario = colaborador.ID_colaborador
      }
      let valorid = Number(this.idUsuario)*6
      this.urlEvaluacion = '/reporteIndividual/'+valorid;   
      this.router.navigate([this.urlEvaluacion]);
    },err => {
      console.log(err);
    });
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }
}