import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { GeneralResponse } from 'app/main/models/generalResponse';
import { Novedades } from 'app/main/models/novedades';
import { PresignedUrlService } from 'app/main/services/presigned-url.service';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ConfigGeneralServices } from '../configuracion/configgeneral.service';
import { NovedadesService } from './novedades.service';
import { Result } from '../../../models/result';
import { DatePipe } from '@angular/common';
import { interval } from 'rxjs';

@Component({
  selector: 'app-colaboradores',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.scss']
})
export class ColaboradoresComponent implements OnInit {
  public generalResponse: GeneralResponse;
  public contentHeader: object;
  public canLoadPlant = false;
  public novedades: Novedades[] = [];
  public uploadUrl: string;
  public submitArchive = false;
  public uploader: FileUploader = new FileUploader({
    isHTML5: true,
    autoUpload: false,
    disableMultipart: true,
    headers: [
      {
        name: 'X-Requested-With',
        value: 'XMLHttpRequest'
      }
    ]
  });
  public preferredUsername = this._authService.getAttributes()['preferred_username'];
  public CODE_SUCCESS = '00';
  public PROCESS_CP = "CP";
  public loadingNovedades = false;
  public showErrorExtention: boolean = false;
  public showDonwload:boolean = false;
  public idButton:string="";
  public intervalReload;
  public timeInterval:number = 180000;
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];


  @ViewChild("modalSuccess") modalSuccess;

  constructor(private _configuracionService: ConfigGeneralServices,
    private _presignedUrlService: PresignedUrlService,
    private _novedadesService: NovedadesService,
    private _authService: AuthenticationService,
    private datePipe: DatePipe,
    private alert: SweetAlertService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getContentHeader();
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => this.onAfterAddingFile(fileItem);
    this.getConfiguration();
    this.getNovedades();

    this.intervalReload = interval(this.timeInterval).subscribe(int => {
      this._novedadesService.getNovedades(this.preferredUsername).subscribe(data => {
        this.generalResponse = data as GeneralResponse;
        this.novedades = this.generalResponse.payload['novedades'];
      }, err => {
        console.error(err);
        this.intervalReload.unsubscribe();
        this.alert.alertNotification('error', 'Error al obtener la novedades, inténtelo más tarde', 5000);
      })
    });
  }

  getConfiguration() {
    const hoy = new Date();
    let dateNow = this.datePipe.transform(hoy, "yyyy-MM-dd");
    let canLoad: boolean;
    this._configuracionService.getConfiguration().subscribe(data => {
      if (data != null) {
        if (dateNow < data['fechas']['inicioEtapa1']) {
          this.canLoadPlant = true;
        } else {
          this.canLoadPlant = false;
        }
      }
    }, err => {
      console.error(err);
      this.alert.alertNotification('error', 'Error al obtener la etapa actual', 5000);
    })
    return canLoad;
  }

  getContentHeader() {
    this.contentHeader = {
      headerTitle: 'Novedades',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Lista',
            isLink: false,
            link: '/'
          }
        ]
      }
    };
  }

  onAfterAddingFile(fileItem: FileItem) {
    let latestFile = this.uploader.queue[this.uploader.queue.length - 1]
    this.uploader.queue = [];
    this.uploader.queue.push(latestFile);
  }

  getNovedades():boolean {
    this.loadingNovedades = true;
    this._novedadesService.getNovedades(this.preferredUsername).subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      if (this.generalResponse.result.estado != 'Exitoso') {
        this.showError(this.generalResponse.result);
      }
      this.novedades = this.generalResponse.payload['novedades'];
      this.loadingNovedades = false;
    }, err => {
      console.error(err);
      this.loadingNovedades = false;
      this.alert.alertNotification('error', 'Error al obtener la novedades, inténtelo más tarde', 5000);
    })
    return false;
  }

  uploadFile() {
    this.submitArchive = true;
    let filename = this.uploader.queue[0].file.name;
    this.validateExtension(filename);
    this._presignedUrlService.getPresignedUrl(filename, 'put_object').subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      if (this.generalResponse.result.estado != this.CODE_SUCCESS) {
        this.showError(this.generalResponse.result);
      }

      this.uploadUrl = this.generalResponse.payload['url'];
      this.uploader.queue.forEach((item) => {
        item.url = this.uploadUrl;
        item.method = 'PUT';
        item.headers = [{ name: 'Content-Type', value: item.file.type }];
        item.withCredentials = false;
        item.upload();
      })

      this.uploader.onErrorItem = (item, response, status, headers) => {
        this.alert.alertNotification('error', 'Hubo un error al subir el archivo, por favor verifique los datos enviados', 5000);
      }

      this.uploader.onSuccessItem = (item, response, status, headers) => {
        this.sendNovedad(this.PROCESS_CP);
        this.modalService.dismissAll();
        this.alert.alertNotification('success', 'Archivo Cargado con exito!', 3000);
      }

      this.submitArchive = false;
    }, err => {
      console.error(err);
      this.submitArchive = false;
      this.alert.alertNotification('error', 'Error al cargar el archivo', 5000);
    })
  }

  validateExtension(fileName: string) {
    let extension = fileName.split('.')[1];
    if (extension != 'xlsx') {
      this.showErrorExtention = true;
      this.uploader.queue = [];
      this.submitArchive = false;
    }
  }

  sendNovedad(proceso:string) {
    let request: any = this.buildRequestSendNovedad(proceso);
    this._novedadesService.sendNovedad(request).subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      //En caso de que se genere una novedad tipo (Recalcular Resultados)
      if(this.generalResponse.result.estado == 'Exitoso' && proceso == 'CR'){
        this.alert.alertNotification('success', 'Nueva novedad con ID:  #' + this.generalResponse.payload['novedades'][0]['idProceso'], 7000);
      }
      if (this.generalResponse.result.estado != 'Exitoso') {
        this.alert.alertNotification('error', 'Error al crear la novedad', 5000);
      }
      this.getNovedades();

      //Reset Mensaje extension (Cargue personal)
      if (this.showErrorExtention) {
        this.showErrorExtention = false;
      }
    }, err => {
      console.error(err);
      this.alert.alertNotification('error', 'Error al cargar el archivo', 5000)
    })
  }

  buildRequestSendNovedad(proceso:string): any {
    let request:any;
    switch(proceso){
      case "CP":
        let filename = this.uploader.queue[0].file.name;
        request = {
          "payload": {
            "novedades": [
              {
                "idColaborador": Number(this.preferredUsername),
                "proceso": "CP",
                "archivo": filename
              }
            ]
          }
        }
        break;
      case "CR":
        request = { 
          "payload": {
            "novedades": [
              {
                "idColaborador": Number(this.preferredUsername),
                "proceso": "CR",
                "tipo": "Monitoreo Etapa 1",
              }
            ]
          }
        }
        break;
    }
    return request;
  }

  descargarArchivo(archivo: string, idProceso:string) {
    this.showDonwload = true;
    this.idButton = idProceso;
    this._presignedUrlService.getPresignedUrl(archivo, 'get_object').subscribe(data => {
      this.generalResponse = data as GeneralResponse;
      if (this.generalResponse.result.estado != this.CODE_SUCCESS) {
        this.showError(this.generalResponse.result);
      }
      if (this.generalResponse.payload['url'] == null) {
        this.alert.alertNotification('error', 'No se pudo obtener la url de descarga, intentelo de nuevo.', 7000);
      }
      let urlDescarga = this.generalResponse.payload['url'];
      window.open(urlDescarga, "_blank");
      this.showDonwload = false;
      this.idButton = "";
    }, err => {
      this.showDonwload = false;
      this.idButton = "";
      console.error(err);
      this.alert.alertNotification('error', 'Error al descargar el archivo.', 5000);
    })
  }

  modalOpenSuccess() {
    this.modalService.open(this.modalSuccess, {
      centered: true,
      windowClass: 'modal modal-success',
      size: 'lg'
    });
  }

  modalInfoDescarga() {
    this.alert.alertNotification('info', 'El archivo solicitado no esta listo para la descarga.', 7000);
  }

  infoCantLoadPlant() {
    this.alert.alertNotification('info', 'El cargue es permitido hasta que inicie a la Etapa 1.', 5000);
  }

  closeModal() {
    this.showErrorExtention = false;
    this.uploader.queue = [];
    this.modalService.dismissAll();
  }

  showError(result: Result) {
    this.alert.alertNotification('error', result.error + " - " + result.descripción, 5000);
  }

  ngOnDestroy(): void{
    this.intervalReload.unsubscribe();
    console.info("interval closed");
  }
}