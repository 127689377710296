import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColumnMode, DatatableComponent, id } from '@swimlane/ngx-datatable';
import { ActivarEvaluacion } from 'app/main/models/requestActivarEvaluacion';
import { RechazarEvaluacion } from 'app/main/models/requestRechazarEvaluacion';
import { Evaluaciones } from 'app/main/models/responseEvaluciones';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { EvaluacionesService } from './evaluaciones.service';

@Component({
  selector: 'app-evaluaciones',
  templateUrl: './evaluaciones.component.html',
  styleUrls: ['./evaluaciones.component.scss']
})
export class EvaluacionesComponent implements OnInit {
  urlImage = environment.urlImages;
  public ColumnMode = ColumnMode;
  contentHeader: object;
  formRechazar:FormGroup;
  formMotivo:FormGroup;
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  searchValue = '';
  public selectStatus: any = [
    { name: 'Todo', value: null },
    { name: 'Aprobado', value: 'Aprobado' },
    { name: 'Rechazado', value: 'Rechazado' }
  ];
  selectedStatus = null;
  tempData = [];
  rows:Evaluaciones[] = [];
  isLoadingPage = false;
  suscription:Subscription;
  attributes360:any;
  showRechazar = false;
  showMotivo = false;
  evaluacionRechazar:Evaluaciones;
  evaluacionMotivo:Evaluaciones;
  rechazando = false;
  activando = false;
  paginacion:any;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private _evaluacionesService:EvaluacionesService, private alert:SweetAlertService,
              private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
    this.getContentHeader();
    this.obtenerEvaluaciones();    
    this.formRechazar = this.formBuilder.group({
      motivo: ['', [Validators.required]]
    })

    this.formMotivo = this.formBuilder.group({
      motivo: ['', [Validators.required]]
    })
  }
  
  getContentHeader() {
    this.contentHeader = {
      headerTitle: 'Evaluaciones',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Listado',
            isLink: false,
            link: '/'
          }
        ]
      }
    };
  }

  obtenerEvaluaciones(){
    this.isLoadingPage = true;
    this._evaluacionesService.obtenerPrimerasEvaluaciones().subscribe(data => {
      if(data != null){
        this.rows = data['datos'] as Evaluaciones[];
        this.paginacion = data['LastEvaluatedKey'];
        this.tempData = this.rows;
        this.isLoadingPage = false;
        this.obtenerEvaluacionesRestantes();
      }
    },err => {
      console.log(err);
      this.isLoadingPage = false;
      this.alert.alertNotification('error', 'Error al obtener las evaluaciones', 3000);
    })
  }

  obtenerEvaluacionesRestantes(){
    this._evaluacionesService.obtenerEvaluaciones(this.paginacion['ID_evaluador'], this.paginacion['ID_evaluado']).subscribe(data => {
      if(data['codRespuesta'] === 0){
        if(data['LastEvaluatedKey'] && data['datos'].length > 0){
          this.paginacion = data['LastEvaluatedKey'];
          this.concatenerRegistros(data['datos'] as Evaluaciones[]);
          this.obtenerEvaluacionesRestantes();
        }else if(!data['LastEvaluatedKey'] && data['datos'].length > 0){
          this.concatenerRegistros(data['datos'] as Evaluaciones[]);
        }
      }
    },err => {
      console.log(err);
    })
  }

  concatenerRegistros(evaluaciones:Evaluaciones[]){
    let siguientesEvaluaciones = evaluaciones
    this.rows = this.rows.concat(siguientesEvaluaciones);
    this.tempData = this.rows;
  }

  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
      return (d.nombreEvaluado && d.nombreEvaluado.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    let filterStatus = this.selectedStatus ? this.selectedStatus['value'] : null;
    if (filterStatus != null) filterStatus =  filterStatus;

    filterAllData = filterAllData.filter(row => {
      return (row.aprobacion === filterStatus) || filterStatus == null;
    });

    this.rows = filterAllData;
    return filterAllData;
  }

  rechazarEvaluacion(){
    if(this.attributes360['preferred_username']){
      Swal.fire({
        title: '¿Esta seguro de rechazar la evaluación?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0033a1',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Rechazar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.rechazando = true;
          let requestRechazar = this.buildRequestRechazar();
          this._evaluacionesService.rechazarEvaluacion(requestRechazar).subscribe(data => {
            if(data != null && data['Mensaje']){
              this.rechazando = false;
              this.formRechazar.reset();
              this.showRechazar = false;
              this.obtenerEvaluaciones();
              this.alert.alertNotification('success', 'Evaluación rechazada!', 2000);
            }
          },err => {
            console.log(err);
            this.rechazando = false;
            this.alert.alertNotification('error', 'Error al rechazar la evaluación', 2000);
          })  
        }
      })
    }
  }

  activarEvaluacion(){
    if(this.attributes360['preferred_username']){
      Swal.fire({
        title: '¿Esta seguro de activar la evaluación?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0033a1',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Activar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.activando = true;
          let requestActivar = this.buildRequestActivar();
          this._evaluacionesService.activarEvaluacion(requestActivar).subscribe(data => {
            if(data != null && data['Mensaje']){
              this.activando = false;
              this.formMotivo.reset();
              this.showMotivo = false;
              this.obtenerEvaluaciones();
              this.alert.alertNotification('success', 'Evaluación activada!', 2000);
            }
          },err => {
            console.log(err);
            this.activando = false;
            this.alert.alertNotification('error', 'Error al activar la evaluación', 2000);
          })  
        }
      })
    }
  }

  buildRequestRechazar():RechazarEvaluacion {
    let requestRechazar = new RechazarEvaluacion();
    requestRechazar.ID_evaluado = this.evaluacionRechazar.ID_evaluado;
    requestRechazar.ID_evaluador = this.evaluacionRechazar.ID_evaluador;
    requestRechazar.motivoInactivacion = this.formRechazar.controls['motivo'].value;
    requestRechazar.ID_revisor = Number(this.attributes360['preferred_username']);
    return requestRechazar;
  }

  buildRequestActivar():ActivarEvaluacion{
    let requestActivar = new ActivarEvaluacion();
    requestActivar.ID_evaluado = this.evaluacionMotivo.ID_evaluado;
    requestActivar.motivoInactivacion = "";
    requestActivar.ID_evaluador = this.evaluacionMotivo.ID_evaluador;
    return requestActivar;
  }

  resetarEvaluacion(idEvaluado:number, idEvaluador:number, nombreEvaluado:string) {
    Swal.fire({
      title: '¿Esta seguro que desea iniciar la evaluación de '+ nombreEvaluado + '?. Se perderán los datos ya guardados.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0033a1',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Resetear',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this._evaluacionesService.resetearEvaluacion(idEvaluado, idEvaluador).subscribe(data => {
          this.obtenerEvaluaciones();
          this.alert.alertNotification('success', 'Evaluacion reseteada con exito!', 3000);
        },err => {
          console.error(err);
          this.alert.alertNotification('error', 'Error al resetear la evaluacion', 5000);
        })
      }
    })
  }

  changeShowRechazar(evaluacion:Evaluaciones){
    this.evaluacionRechazar = evaluacion;
    this.showRechazar = true;
  }

  changeShowMotivo(evaluacion:Evaluaciones){
    this.evaluacionMotivo = evaluacion;
    this.formMotivo.controls['motivo'].setValue(this.evaluacionMotivo.motivo);
    this.showMotivo = true;
  }

  cancelShowRechazar(){
    this.showRechazar = false;
    this.formRechazar.reset();
  }

  cancelShowMotivo(){
    this.showMotivo = false;
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }
}