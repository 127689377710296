// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  apiResetEvaluaciones: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/evaluacion/reset',
  apiMonitoreo: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/monitoreo',
  apiEvaluaciones: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/monitoreo',
  apiMonitoreo2: 'https://a3pujq9c6k.execute-api.us-east-1.amazonaws.com/dev/evaluacion360/monitoreo',
  apiGeneral: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360',
  apiConfiguracion: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/configuracion',
  apiResultadosArea: 'https://a3pujq9c6k.execute-api.us-east-1.amazonaws.com/dev/evaluacion360/AreasyCargos',
  apiResultadosA: 'https://a3pujq9c6k.execute-api.us-east-1.amazonaws.com/dev/evaluacion360/ReporteAreas',
  apiResultados: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/resultados',
  apiPersonas: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/personas',
  apiResultadosComp: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/Rcompetencias',
  apiVerPlanDesarrollo: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/TraerPlandeDesarrollo',
  apiActividades: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/ServcioActividades',
  apiAddActividades: 'https://6c5zxdjfpj.execute-api.us-east-1.amazonaws.com/desarrollo/evaluacion360/plandedesarrollo',
  apiNovedades: 'https://a3pujq9c6k.execute-api.us-east-1.amazonaws.com/dev/evaluacion360/novedades',
  apiPresignedUrl: 'https://a3pujq9c6k.execute-api.us-east-1.amazonaws.com/dev/evaluacion360/utils/signed',
  urlImages: 'https://imagenes-funcionarios.s3.amazonaws.com/',
  apiResults: 'https://a3pujq9c6k.execute-api.us-east-1.amazonaws.com/dev/evaluacion360',
  apiUrl: 'http://localhost:4000'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
