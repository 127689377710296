import { Component, OnInit, ViewEncapsulation,ViewChild, ElementRef,SimpleChanges  } from '@angular/core';
import { colors } from 'app/colors.const';
import { jsPDF } from "jspdf";
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { ConfigGeneralServices } from '../../etapa1/configuracion/configgeneral.service';
import { VerReporteserviceService} from '../ver-reporteindi/ver-reporteservice.service';
import {ArrayObjetos,ArrayPerson,DatosPerson,ObjetoCompetencias,Compe,DatosUsu,ResultDatos,datosOrganizacion,Arraydetalles,Objetodetalle,datosTotales} from '../../../models/resultados';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates
} from 'ng-apexcharts';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';

export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}

@Component({
  selector: 'app-ver-reporteindi',
  templateUrl: './ver-reporteindi.component.html',
  styleUrls: ['./ver-reporteindi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerReporteindiComponent implements OnInit {
  attributes360:any;
  idusuario: number ;
  @ViewChild('apexRadarChartRef') apexRadarChartRef: any;
  @ViewChild('apexDonutChartRef') apexDonutChartRef: any;
  idColaborador: number ;

  usuario!:{idUsuario:number}

  arrayObjetos: ArrayObjetos;
  cargandoReporte = false;
  content = false;
  descarga = true;
  descargado = false;
  arrayPersona:ArrayPerson;
  objetoCompetencias:ObjetoCompetencias;
  DetalladoCompetencia:Arraydetalles;
  totalCompetenciasEva: datosTotales

  datosPerson:DatosPerson;
  datosCompe:Compe;

  datos: Array<DatosPerson> 
  lista: Array<Compe>
  detalles : Array<Objetodetalle>

  showDiv: boolean
  nombre: String
  cedula: number
  cargof: String
  area: String
  nombreJefe: String
  totalEvaluacionAnterior: number
  totalEvaluacion: number
  periodo: String
  cargolider: String
  prueba: number
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];

  async obtenerResultados() {
    this.cargandoReporte = true;
    await this.service.obtenerResultados(this.idColaborador).toPromise().then((result: ArrayObjetos) => {
      this.arrayObjetos = result;
      this.arrayPersona = this.arrayObjetos[0]
      this.objetoCompetencias = this.arrayObjetos[1]
      console.log(this.objetoCompetencias);
      this.lista = this.objetoCompetencias.competencias
      this.DetalladoCompetencia = this.arrayObjetos[2]

      this.totalCompetenciasEva = this.arrayObjetos[3]

      console.log(this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].totalpregunta)

      this.datos = this.arrayPersona.datos
      this.detalles = this.arrayObjetos[2]
      console.log(this.datos[0].totalCliente)
      this.cedula = this.datos[0].idColaborador
      this.nombre = this.datos[0].nombre
      this.cargof = this.datos[0].cargoColaborador
      this.area = this.datos[0].areaColaborador
      this.nombreJefe = this.datos[0].nombreLider
      this.totalEvaluacion = this.datos[0].totalEvaluacion
      this.totalEvaluacionAnterior = this.datos[0].notaAnterior;
      this.periodo = this.datos[0].Periodo
      this.cargolider = this.datos[0].cargoLíder
      if(this.totalCompetenciasEva.totalcompetencia.LiderazgototalCompetencia==0)
        this.showDiv=false
      else
        this.showDiv=true
      

      //bar chart
      this.barChart.datasets[0].data[0] = this.totalCompetenciasEva.totalcompetencia.DesemSuperiortotalCompetencia
      this.barChart.datasets[0].data[1] = this.totalCompetenciasEva.totalcompetencia.FlexibilidadtotalCompetencia
      this.barChart.datasets[0].data[2] = this.totalCompetenciasEva.totalcompetencia.InnovaciontotalCompetencia
      this.barChart.datasets[0].data[3] = this.totalCompetenciasEva.totalcompetencia.CorresponsabilidadtotalCompetencia
      this.barChart.datasets[0].data[4] = this.totalCompetenciasEva.totalcompetencia.agilidadtotalCompetencia
      this.barChart.datasets[0].data[5] = this.totalCompetenciasEva.totalcompetencia.DCargototalCompetencia
      // if(this.showDiv)
      this.barChart.datasets[0].data[6] = this.totalCompetenciasEva.totalcompetencia.LiderazgototalCompetencia

      this.barChart.datasets[1].data[0] = this.totalCompetenciasEva.totalcompetencia.DesemSuperiortotalAutoevaluacion
      this.barChart.datasets[1].data[1] = this.totalCompetenciasEva.totalcompetencia.FlexibilidadtotalAutoevaluacion
      this.barChart.datasets[1].data[2] = this.totalCompetenciasEva.totalcompetencia.InnovaciontotalCompetencia
      this.barChart.datasets[1].data[3] = this.totalCompetenciasEva.totalcompetencia.CorresponsabilidadtotalAutoevaluacion
      this.barChart.datasets[1].data[4] = this.totalCompetenciasEva.totalcompetencia.agilidadtotalAutoevaluacion
      this.barChart.datasets[1].data[5] = this.totalCompetenciasEva.totalcompetencia.DCargototalAutoevaluacion
      // if(this.showDiv)
      this.barChart.datasets[1].data[6] = this.totalCompetenciasEva.totalcompetencia.LiderazgototalAutoevaluacion

      this.apexDonutChart.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].A
      this.apexDonutChart.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].B
      this.apexDonutChart.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].C
      this.apexDonutChart.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].D
      this.apexDonutChart.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[0].E

      this.apexDonutChart2.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].A
      this.apexDonutChart2.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].B
      this.apexDonutChart2.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].C
      this.apexDonutChart2.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].D
      this.apexDonutChart2.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[1].E

      this.apexDonutChart3.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].A
      this.apexDonutChart3.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].B
      this.apexDonutChart3.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].C
      this.apexDonutChart3.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].D
      this.apexDonutChart3.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[6].DesempenoSuperior[2].E

      this.apexDonutChart4.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].A
      this.apexDonutChart4.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].B
      this.apexDonutChart4.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].C
      this.apexDonutChart4.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].D
      this.apexDonutChart4.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[0].E

      this.apexDonutChart5.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].A
      this.apexDonutChart5.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].B
      this.apexDonutChart5.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].C
      this.apexDonutChart5.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].D
      this.apexDonutChart5.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[1].E

      this.apexDonutChart6.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].A
      this.apexDonutChart6.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].B
      this.apexDonutChart6.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].C
      this.apexDonutChart6.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].D
      this.apexDonutChart6.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[5].FlexibilidadalCambio[2].E

      this.apexDonutChart7.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].A
      this.apexDonutChart7.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].B
      this.apexDonutChart7.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].C
      this.apexDonutChart7.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].D
      this.apexDonutChart7.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[0].E

      this.apexDonutChart8.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].A
      this.apexDonutChart8.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].B
      this.apexDonutChart8.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].C
      this.apexDonutChart8.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].D
      this.apexDonutChart8.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[1].E

      this.apexDonutChart9.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].A
      this.apexDonutChart9.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].B
      this.apexDonutChart9.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].C
      this.apexDonutChart9.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].D
      this.apexDonutChart9.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[4].Innovacion[2].E

      this.apexDonutChart10.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].A
      this.apexDonutChart10.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].B
      this.apexDonutChart10.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].C
      this.apexDonutChart10.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].D
      this.apexDonutChart10.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[0].E

      this.apexDonutChart11.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].A
      this.apexDonutChart11.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].B
      this.apexDonutChart11.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].C
      this.apexDonutChart11.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].D
      this.apexDonutChart11.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[1].E

      this.apexDonutChart12.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].A
      this.apexDonutChart12.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].B
      this.apexDonutChart12.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].C
      this.apexDonutChart12.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].D
      this.apexDonutChart12.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[3].Corresponsabilidad[2].E
      if(this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador== null){

      this.apexDonutChart13.series[0] = 0
      this.apexDonutChart13.series[1] = 0
      this.apexDonutChart13.series[2] = 0
      this.apexDonutChart13.series[3] = 0
      this.apexDonutChart13.series[4] = 0

      this.apexDonutChart14.series[0] = 0
      this.apexDonutChart14.series[1] = 0
      this.apexDonutChart14.series[2] = 0
      this.apexDonutChart14.series[3] = 0
      this.apexDonutChart14.series[4] = 0

      this.apexDonutChart15.series[0] = 0
      this.apexDonutChart15.series[1] = 0
      this.apexDonutChart15.series[2] = 0
      this.apexDonutChart15.series[3] = 0
      this.apexDonutChart15.series[4] = 0
      }else{
      this.apexDonutChart13.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].A
      this.apexDonutChart13.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].B
      this.apexDonutChart13.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].C
      this.apexDonutChart13.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].D
      this.apexDonutChart13.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[0].E

      this.apexDonutChart14.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].A
      this.apexDonutChart14.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].B
      this.apexDonutChart14.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].C
      this.apexDonutChart14.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].D
      this.apexDonutChart14.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[1].E

      this.apexDonutChart15.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].A
      this.apexDonutChart15.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].B
      this.apexDonutChart15.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].C
      this.apexDonutChart15.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].D
      this.apexDonutChart15.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[0].LiderazgoTransformador[2].E
      }
      this.apexDonutChart16.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].A
      this.apexDonutChart16.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].B
      this.apexDonutChart16.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].C
      this.apexDonutChart16.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].D
      this.apexDonutChart16.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[0].E

      this.apexDonutChart17.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].A
      this.apexDonutChart17.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].B
      this.apexDonutChart17.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].C
      this.apexDonutChart17.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].D
      this.apexDonutChart17.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[1].E

      this.apexDonutChart18.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].A
      this.apexDonutChart18.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].B
      this.apexDonutChart18.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].C
      this.apexDonutChart18.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].D
      this.apexDonutChart18.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[2].AgilidadOrganizacional[2].E

      // this.apexDonutChart19.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.A
      // this.apexDonutChart19.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.B
      // this.apexDonutChart19.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.C
      // this.apexDonutChart19.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.D
      // this.apexDonutChart19.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo.E

      this.apexDonutChart19.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].A
      this.apexDonutChart19.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].B
      this.apexDonutChart19.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].C
      this.apexDonutChart19.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].D
      this.apexDonutChart19.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[0].E

      this.apexDonutChart20.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].A
      this.apexDonutChart20.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].B
      this.apexDonutChart20.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].C
      this.apexDonutChart20.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].D
      this.apexDonutChart20.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[1].E

      this.apexDonutChart21.series[0] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].A
      this.apexDonutChart21.series[1] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].B
      this.apexDonutChart21.series[2] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].C
      this.apexDonutChart21.series[3] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].D
      this.apexDonutChart21.series[4] = this.DetalladoCompetencia.DetalladoCompetencia[1].DesempenodelCargo[2].E

      this.cargandoReporte = false;
      this.content = true;

    }, err => {
      console.error(err);
      this.cargandoReporte = false;
      this.alert.alertNotification('error', 'Error al obtener el reporte.', 5000);
    })
  }

 // public
 public contentHeader: object;
 public radioModel = 1;
 public apexDonutChart: Partial<ChartOptions2>;
 public apexDonutChart2: Partial<ChartOptions2>;
 public apexDonutChart3: Partial<ChartOptions2>;
 public apexDonutChart4: Partial<ChartOptions2>;
 public apexDonutChart5: Partial<ChartOptions2>;
 public apexDonutChart6: Partial<ChartOptions2>;
 public apexDonutChart7: Partial<ChartOptions2>;
 public apexDonutChart8: Partial<ChartOptions2>;
 public apexDonutChart9: Partial<ChartOptions2>;
 public apexDonutChart10: Partial<ChartOptions2>;
 public apexDonutChart11: Partial<ChartOptions2>;
 public apexDonutChart12: Partial<ChartOptions2>;
 public apexDonutChart13: Partial<ChartOptions2>;
 public apexDonutChart14: Partial<ChartOptions2>;
 public apexDonutChart15: Partial<ChartOptions2>;
 public apexDonutChart16: Partial<ChartOptions2>;
 public apexDonutChart17: Partial<ChartOptions2>;
 public apexDonutChart18: Partial<ChartOptions2>;
 public apexDonutChart19: Partial<ChartOptions2>;
 public apexDonutChart20: Partial<ChartOptions2>;
 public apexDonutChart21: Partial<ChartOptions2>;
 public isMenuToggled = false;
 chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff'
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f'
  },
  donut: {
    series1: ' #ffda9e ',
    series2: ' #84b6f4 ',
    series3: ' #ff6961 ',
    series4: '  #fdcae1  ',
    series5: '  #77dd77  '
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7'
  }
};


 // Color Variables
 private successColorShade = '#ee3b3b ';
 private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
 private labelColor = '#6e6b7b';
 private grid_line_color = 'rgba(200, 200, 200, 0.2)'; // RGBA color helps in dark layout

 // Bar Chart
  public barChart = {
 
   chartType: 'bar',
   datasets: [
     {
       label: 'Evaluación',
       data: [1,  1,  1,  1,  1,  1,  1],
       backgroundColor: [
        'rgba( 15, 45, 131 )',
        'rgba( 15, 45, 131 )',
        'rgba( 15, 45, 131 )',
        'rgba( 15, 45, 131 )',
        'rgba( 15, 45, 131 )',
        'rgba( 15, 45, 131 )',
        'rgba( 15, 45, 131 )',
      ],
       borderColor: 'transparent',
       hoverBackgroundColor: 'rgba( 15, 45, 131 )',
       hoverBorderColor: this.successColorShade
     },{
      label: 'Autoevaluación',
      data: [  1, 1,  1,  1,  1,  1, 1],
      backgroundColor: [
       'rgba(  241, 158, 46  )',
       'rgba(  241, 158, 46  )',
       'rgba(  241, 158, 46  )',
       'rgba(  241, 158, 46  )',
       'rgba(  241, 158, 46  )',
       'rgba(  241, 158, 46  )',
       'rgba(  241, 158, 46  )',
     ],
      borderColor: 'transparent',
      hoverBackgroundColor: 'rgba(  241, 158, 46  )',
      hoverBorderColor: this.successColorShade
    }
   ],
   labels: ['Desempeño superior', 'Flexibilidad al cambio', 'Innovación', 'Corresponsabilidad', 'Agilidad organizacional', 'Desempeño del cargo','Liderazgo transformador'],
   options: {
   
     elements: {
       rectangle: {
         borderWidth: 2,
         borderSkipped: 'bottom'
       }
     },
     responsive: true,
     maintainAspectRatio: false,
     responsiveAnimationDuration: 500,
     legend: {
       display: true
     },
     tooltips: {
       // Updated default tooltip UI
       shadowOffsetX: 1,
       shadowOffsetY: 1,
       shadowBlur: 8,
       shadowColor: this.tooltipShadow,
       backgroundColor: colors.solid.white,
       titleFontColor: colors.solid.black,
       bodyFontColor: colors.solid.black
     },
     scales: {
       xAxes: [
         {
           barThickness: 15,
           display: true,
           gridLines: {
             display: true,
             color: this.grid_line_color,
             zeroLineColor: this.grid_line_color
           },
           scaleLabel: {
             display: true
           },
           ticks: {
             fontColor: this.labelColor
           }
         }
       ],
       yAxes: [
         {
           display: true,
           gridLines: {
             color: this.grid_line_color,
             zeroLineColor: this.grid_line_color
           },
           ticks: {
             stepSize: 1,
             min: 0,
             max: 5,
             fontColor: this.labelColor
           }
         }
       ]
     }
   },
   legend: true
 };

 //** To add spacing between legends and chart
 public plugins = [
   {
     beforeInit(chart) {
       chart.legend.afterFit = function () {
         this.height += 20;
       };
     }
   }
 ];

 mensaje: any

 constructor(private router:Router,private service: VerReporteserviceService,private rutaActiva: ActivatedRoute,private _configuracionService:ConfigGeneralServices,private alert:SweetAlertService) {
  this.apexDonutChart = {
    series: [1, 1, 1, 1,1],
    chart: {
      height: 350,
      type: 'donut'
    },
    colors: [
      this.chartColors.donut.series1,
      this.chartColors.donut.series2,
      this.chartColors.donut.series3,
      this.chartColors.donut.series4,
      this.chartColors.donut.series5
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat'
            },
            total: {
              show: true,
              fontSize: '1.5rem',
              label: 'Colaboradores',
              formatter: function (w) {
                return '100%';
              }
            }
          }
        }
      }
    },
    legend: {
      show: true,
      position: 'bottom'
    },
    labels: ['A', ' B', ' C', ' D','E'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  this.apexDonutChart2 = {
    series: [1, 1, 1, 1,1],
    chart: {
      height: 350,
      type: 'donut'
    },
    colors: [
      this.chartColors.donut.series1,
      this.chartColors.donut.series2,
      this.chartColors.donut.series3,
      this.chartColors.donut.series4,
      this.chartColors.donut.series5
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat'
            },
            value: {
              fontSize: '1rem',
              fontFamily: 'Montserrat',
              formatter: function (val) {
                return parseInt(val) + '%';
              }
            },
            total: {
              show: true,
              fontSize: '1.5rem',
              label: 'Colaboradores',
              formatter: function (w) {
                return '100%';
              }
            }
          }
        }
      }
    },
    legend: {
      show: true,
      position: 'bottom'
    },
    labels: ['A', ' B', ' C', ' D','E'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  this.apexDonutChart3 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart4 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart5 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart6 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart7 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart8 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart9 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart10 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart11 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart12 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart13 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart14 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart15 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart16 = {
    series: [1, 1, 1, 1,1]    
  };
  this.apexDonutChart17 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart18 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart19 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart20 = {
    series: [1, 1, 1, 1,1]    
  };

  this.apexDonutChart21 = {
    series: [1, 1, 1, 1,1]    
  };
 }

 

 
 ngOnInit() {
  this.attributes360 = JSON.parse(localStorage.getItem('payload'));
  this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
  this.idusuario = Number(this.attributes360['preferred_username']);
  
    this._configuracionService.getConfiguration().subscribe(data => {
      console.log(data['etapaActual'])
      if(data != null){
        if(data['etapaActual'] != "Etapa 3"){
          if(this.payload == "Administradores"){
            this.initReporteInd();
          }else{
            this.alert.alertNotification('info', 'La etapa 3 estará activa desde ' + data['fechas']['inicioEtapa3'] + ' hasta ' + data['fechas']['finEtapa3'], 5000);
            this.router.navigate(['evaluadores']);
          }
        }else{
          this.initReporteInd();
        }
      }
    },err => {
      console.log(err);
    })
   // content header
   
 }

 initReporteInd(){
  
  this.getContentHeader();
          this.usuario = {
            idUsuario: this.rutaActiva.snapshot.params.idUsuario
          };
          this.rutaActiva.params.subscribe(
           (params: Params)=>{
             this.usuario.idUsuario = params.idUsuario;
             this.idColaborador = Number(params.idUsuario)/6;
       
           }
         );
          console.log(this.usuario.idUsuario)
          this.obtenerResultados();
}

 getContentHeader(){
  this.contentHeader = {
    headerTitle: 'Reporte individual',
    breadcrumb: {
      type: '',
      links: [
        {
          name: 'reportes',
          isLink: true,
          link: '/'
        },
        {
          name: 'colaborador',
          isLink: true,
          link: '/'
        }
      ]
    }
  };
 }

 public pruPdf(): void {
  this.descarga = false
  this.descargado = true
  let pag1 = document.getElementById('pag1');
  let pag2 = document.getElementById('pag2');
  let pag3 = document.getElementById('pag3');
  let pag4 = document.getElementById('pag4');
  let pag5 = document.getElementById('pag5');
  let pag6 = document.getElementById('pag6');
  let pag7 = document.getElementById('pag7');
  let pag8 = document.getElementById('pag8');

  domtoimage.toPng(pag1).then((dataUrl) => {
    let imagen = new Image();
    imagen.src = 'assets/images/portadared3.png';/*obtengo el screenshot*/
    let pdf = new jsPDF('portrait', 'mm', 'a4');
    
    pdf.addImage(imagen, 15, 7, 180, 260);
    //portada
    domtoimage.toPng(pag1).then((dataUrl1) => {
      pdf.addPage();
      let imagen1 = new Image();
      imagen1.src = dataUrl1;/*obtengo el screenshot*/
      pdf.addImage(imagen1, 'PNG', 9, 5, 195, 260, '', 'FAST');
      //pag1
      domtoimage.toPng(pag2).then((dataUrl2) => {
        pdf.addPage();
        let imagen2 = new Image();
        imagen2.src = dataUrl2;/*obtengo el screenshot*/
        pdf.addImage(imagen2, 'PNG', 6, 5, 200, 290, '', 'FAST');
        //pag2
        domtoimage.toPng(pag3).then((dataUrl3) => {
          pdf.addPage();
          let imagen3 = new Image();
          imagen3.src = dataUrl3;/*obtengo el screenshot*/
          pdf.addImage(imagen3, 'PNG', 6, 5, 200, 290, '', 'FAST');
          //pag3
          domtoimage.toPng(pag4).then((dataUrl4) => {
            pdf.addPage();
            let imagen4 = new Image();
            imagen4.src = dataUrl4;/*obtengo el screenshot*/
            pdf.addImage(imagen4, 'PNG', 6, 5, 200, 290, '', 'FAST');
            //pag4
            domtoimage.toPng(pag5).then((dataUrl5) => {
              pdf.addPage();
              let imagen5 = new Image();
              imagen5.src = dataUrl5;/*obtengo el screenshot*/
              pdf.addImage(imagen5, 'PNG', 6, 5, 200, 290, '', 'FAST');
              //pag5 
              if(pag6==null){
                domtoimage.toPng(pag7).then((dataUrl7) => {
                  pdf.addPage();
                  let imagen7 = new Image();
                  imagen7.src = dataUrl7;/*obtengo el screenshot*/
                  pdf.addImage(imagen7, 'PNG', 6, 5, 200, 290, '', 'FAST');
                  //pag7
                  domtoimage.toPng(pag8).then((dataUrl8) => {
                    pdf.addPage();
                    let imagen8 = new Image();
                    imagen8.src = dataUrl8;/*obtengo el screenshot*/
                    pdf.addImage(imagen8, 'PNG', 6, 5, 200, 290, '', 'FAST');
                    //pag8
                    pdf.save('Resultado Evaluación.pdf');
                    this.descarga = true
                    this.descargado = false
                  });
                });
              }
              else{
              domtoimage.toPng(pag6).then((dataUrl6) => {
                pdf.addPage();
                let imagen6 = new Image();
                imagen6.src = dataUrl6;/*obtengo el screenshot*/
                pdf.addImage(imagen6, 'PNG', 6, 5, 200, 290, '', 'FAST');
                //pag6
                domtoimage.toPng(pag7).then((dataUrl7) => {
                  pdf.addPage();
                  let imagen7 = new Image();
                  imagen7.src = dataUrl7;/*obtengo el screenshot*/
                  pdf.addImage(imagen7, 'PNG', 6, 5, 200, 290, '', 'FAST');
                  //pag7
                  domtoimage.toPng(pag8).then((dataUrl8) => {
                    pdf.addPage();
                    let imagen8 = new Image();
                    imagen8.src = dataUrl8;/*obtengo el screenshot*/
                    pdf.addImage(imagen8, 'PNG', 6, 5, 200, 290, '', 'FAST');
                    //pag8
                    pdf.save('Resultado Evaluación.pdf');
                    this.descarga = true
                    this.descargado = false
                  });
                });
              }
            );
          }
            });
          });
        });
      });
    });
  });
}


}
