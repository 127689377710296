<div class="content-wrapper container-xxl p-0"  *ngIf="main">
    <div class="content-body" id="htmlData">
      <!-- Content-Header Component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>

      <div class="col-sm-12 col-md-24 d-flex align-items-center justify-content-center" *ngIf="cargandoPlan">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="cargaPlan">
        <div class="col-md-24" id="pag1" >
          <!--Datos funcionario-->
          <section >
           <div class="row">
               <div class="col-12">
                   <div class="card">
                     <div class="card-header" style="justify-content: center;">
                         <div class="row">
                           <div >
                             <span class="card-title" style="font-size: 30px; margin-bottom: 5px;">PLAN DE DESARROLLO INDIVIDUAL</span><br>
                             <h6 class="font-weight-bolder" style="margin-left: 25%;" >Datos del Colaborador</h6>
                           </div>                     
                         </div>
                     </div>
                     <div class="card-body">
                         <div class="row">
                             <div class="col-xl-6 col-6">
                               <div class="table-responsive" >
                                 <table class="table" >
                                   <tbody >
                                     <tr> 
                                       <th style="border: 0;" >Nombre del Colaborador</th>
                                       <th style="border: 0;" > <h6>{{plan.nombre}}</h6>  </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Cédula</th>
                                       <th style="border: 0;" > <h6>{{plan.idColaborador}}</h6> </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Cargo del Colaborador</th>
                                       <th style="border: 0;" > <h6>{{plan.cargoColaborador}}</h6> </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Área</th>
                                       <th style="border: 0;" > <h6>{{plan.areaColaborador}}</h6> </th>
                                     </tr>
           
                                   </tbody>
                                 </table>
                               </div>
   
                             </div>
                             <div class="col-xl-6 col-6">
                               <div class="table-responsive" >
                                 <table class="table" >
                                   <tbody >
                                     <tr> 
                                       <th style="border: 0;" >Nombre del Líder</th>
                                       <th style="border: 0;" > <h6>{{plan.nombreJefe}}</h6> </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Cargo del Líder</th>
                                       <th style="border: 0;" > <h6>{{plan.cargoJefe}}</h6>  </th>
                                     </tr>
                                     <tr> 
                                       <th style="border: 0;" >Período</th>
                                       <th style="border: 0;" ><h6>2022</h6> </th>
                                     </tr>
           
                                   </tbody>
                                 </table>
                               </div>
                             </div>
                         </div>
                     </div>
                   </div>
               </div>
           </div>
           <!-- Tabla 1-->
          <section id="bar-and-hori-bar-charts">
           <div class="row">      
             <div class="col-xl-12 col-24">
               <div class="card">
                 <div class="row" id="table-contextual">
                   <div class="col-12">
                     <div class="card">                    
                       <div class="card-body" > 
                         <div class="table-responsive" style="margin-top: 30px;">
                           <table class="table">
                             <thead>
                               <tr style="text-align: center;">
                                <th>Competencia</th>
                                 <th>Tipo de actividad</th>
                                 <th>Descripción</th>
                                 <th>Fecha inicio</th>
                                 <th>Costo</th>
                                 <th>Eliminar</th>
                                 <th>Actualizar</th>
                               </tr>
                             </thead>
                             <tbody>
                               <tr class="table-default" *ngFor="let dato of datos; let i = index">
                                <td> {{dato.COMPETENCIA}}</td>
                                 <td>
                                   {{dato.TIPODEACTIVIDAD}}
                                 </td>

                                 <td>{{dato.DESCRIPCION}} </td>
                                 <td>{{dato.FECHA}}</td>
                                 <td > ${{dato.COSTO}}  </td>
                                 <td>
                                  <button type="button" class="btn btn-danger"  (click)="eliminarActividad(dato)" ><span [data-feather]="'trash'"></span></button>
                                 </td>
                                 <td>
                                  <button type="button" class="btn btn-warning" (click)="editarActividad(dato)"  ><span [data-feather]="'edit'"></span></button>
                                 </td>
                                 
                               </tr>
     
                             </tbody>
                           </table>
                         </div>
                       </div>           
                     </div>
                   </div>
                 </div>
                 <div class="card-footer">
                   <button type="button" class="btn btn-primary" style="margin-left: 46%;" (click)="addActividad()" >Añadir</button>
                   
                 </div>  
               </div>
             </div>
             <!--/ Tabla 1 End -->
           </div>
            </section>
            <!-- Bar Chart Start -->
          </section>
          <!--Datos funcionario End-->
         </div>

      </div>

    </div>
</div>

<div class="editar" *ngIf="editar">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-6">
        <h1 class="mt-2 mb-4 text-center">Editar Actividad </h1>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-12 col-12">
                      <div class="table-responsive" >
                        <table class="table" >
                          <tbody >
                            <tr> 
                              <th style="border: 0;" >Nombre del Funcionario</th>
                              <th style="border: 0;" > 
                                <div class="media d-flex align-items-center">
                                <div class="avatar mr-75">
                                    <img src="{{urlImage}}{{idColaborador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                        class="" width="42" height="42" data-placement="left"/>
                                </div>
                                <div class="media-body my-auto">
                                    <h6 class="mb-0">{{plan.nombre}}</h6>
                                </div>
                                </div> 
                              </th>
                            </tr>
                            <tr> 
                              <th style="border: 0;" >Competencia</th>
                              <th style="border: 0;" > 
                                <div class="d-flex mb-2">
                                
                                    <select id="competencia" class="form-control form-control" (change)="selectcompe($event)">
                                        <option selected value={{datocompe}}>{{datocompe}}</option>
                                        <option *ngFor="let dato of da2">{{dato.competencia}}</option>
                                      </select>
                                
                                </div> 
                               </th>
                            </tr>
                            <tr> 
                              <th style="border: 0;" >Tipo de actividad</th>
                              <th style="border: 0;" > 
                                <div class="d-flex mb-2">
                                
                                    <select class="form-control form-control" (change)="selectacti($event)">
                                        <option  selected value={{datoacti}} selected>{{datoacti}}</option>
                                        <option  *ngFor="let dato of da1">{{dato.nombreActividad}}</option>
                                    </select>
                                
                                </div>
                              </th>
                            </tr>
                            <tr> 
                              <th style="border: 0;" >Descripción</th>
                              <th style="border: 0;" > 
                                <form >
                                <fieldset class="form-group">
                                    <textarea class="descripcion form-control" (change)="selectdescri($event)" formControlName="descripcion" id="textAreadescripcion" rows="3"
                                        placeholder="Agrega una descripción" value={{datodesc}}
                                        ></textarea>             
                                </fieldset>
                                </form> 
                              </th>
                            </tr>
                            <tr> 
                                <th style="border: 0;" >Fecha de inicio</th>
                                <th style="border: 0;" > 
                                  <form [formGroup]="form">
                                    
                                    <input class="date form-control" (change)="selectfecha($event)"  formControlName="date" id="date" type="date" />
                                  </form>

                                  </th>
                              </tr>
                            <tr> 
                                <th style="border: 0;" >Costo</th>
                                <th style="border: 0;" > 
                                <form [formGroup]="form">
                                  
                                  <fieldset class="form-group">

                                          <input class="costo form-control" (change)="selectcosto($event)" placeholder="Agrega un costo (Solo números)" formControlName="number"  id="number" type="text">
                                  
                                  </fieldset>
                                </form>
                                     <div *ngIf="f.number.touched && f.number.invalid" >
                                       <div *ngIf="f.number.errors.pattern">Ingresa solo números.</div>
                                    
                                     </div> 
                                </th>
                            </tr>
  
                          </tbody>
                        </table>
                      </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <button class="btn btn-danger" (click)="cancelar()">
                Cancelar
            </button>
            <button [disabled]="!form.valid" class="btn btn-success mx-1" (click)="enviarActividad()" >Agregar</button>

        </div>          
    </div>
</div>
</div>
