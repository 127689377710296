import { CoreMenu } from '@core/types'

export const menuUsuario: CoreMenu[] = [
  {
    id: 'etapa0',
    title: '',
    type: 'section',
    icon: 'clock',
    children: [
      
    ]
  },
  {
    id: 'etapa1',
    title: 'ETAPA 1 - POSTULACIÓN',
    type: 'section',
    icon: 'clock',
    children: [
      {
        id: 'registroEvaluadores',
        title: 'Evaluadores',
        type: 'item',
        icon: 'user-plus',
        url: 'evaluadores',
      },
    ]
  },
  {
    id: 'etapa2',
    title: 'ETAPA 2 - EVALUACIÓN',
    type: 'section',
    children: [
      {
        id: 'evaluacionDesempeno',
        title: 'Iniciar Evaluación',
        type: 'item',
        icon: 'file-text',
        url: 'evaluacion'
      }
    ]
  },
   {
     id: 'etapa3',
     title: 'ETAPA 3 - REPORTES',
     type: 'section',
     children: [
      {
       id: 'reporte-indusu',
       title: 'Mi reporte',
       type: 'item',
       icon: 'activity',
       url: 'miReporte'
      },
      {
       id: 'miplandesarrollo',
       title: 'Mi Plan de Desarrollo',
       type: 'item',
       icon: 'book-open',
       url: 'miplandesarrollo'
      }
     ]
   },
]

export const menuJefe: CoreMenu[] = [
  {
    id: 'etapa0',
    title: '',
    type: 'section',
    icon: 'clock',
    children: [
      {
        id: 'novedades',
        title: 'Novedades',
        type: 'item',
        icon: 'user-check',
        url: 'novedades',
      }
    ]
  },
  {
    id: 'etapa1',
    title: 'ETAPA 1 - POSTULACIÓN',
    type: 'section',
    icon: 'clock',
    children: [
      {
        id: 'registroEvaluadores',
        title: 'Evaluadores',
        type: 'item',
        icon: 'user-plus',
        url: 'evaluadores',
      },
      {
        id: 'revisarPostulaciones',
        title: 'Revisar Postulaciones',
        type: 'item',
        icon: 'user-check',
        url: 'postulaciones',
      }
    ]
  },
  {
    id: 'etapa2',
    title: 'ETAPA 2 - EVALUACIÓN',
    type: 'section',
    children: [
      {
        id: 'evaluacionDesempeno',
        title: 'Iniciar Evaluación',
        type: 'item',
        icon: 'file-text',
        url: 'evaluacion'
      }
    ]
  },
   {
     id: 'etapa3',
     title: 'ETAPA 3 - REPORTES',
     type: 'section',
     children: [
       {
        id: 'reporte-indi',
        title: 'Reporte Colaboradores',
        type: 'item',
        icon: 'activity',
        url: 'reporteindi'
       },
       {
        id: 'reporte-indusu',
        title: 'Mi reporte',
        type: 'item',
        icon: 'activity',
        url: 'miReporte'
       },
       {
        id: 'plandesarrollo',
        title: 'Plan de Desarrollo',
        type: 'item',
        icon: 'book-open',
        url: 'plandesarrollo'
       },
       {
        id: 'miplandesarrollo',
        title: 'Mi Plan de Desarrollo',
        type: 'item',
        icon: 'book-open',
        url: 'miplandesarrollo'
       },
       {
        id: 'resultados',
        title: 'Resultados',
        type: 'item',
        icon: 'check-circle',
        url: 'resultados'
       },
       {
         id: 'nineboxtalent',
         title: 'Nine Box Talent',
         type: 'item',
         icon: 'check-circle',
         url: 'nineBoxTalent'
        },
     ]
   },
]

export const menuAdministrador: CoreMenu[] = [
  {
    id: 'etapa0',
    title: '',
    type: 'section',
    icon: 'clock',
    children: [
      {
        id: 'novedades',
        title: 'Novedades',
        type: 'item',
        icon: 'user-check',
        url: 'novedades',
      },
      {
        id: 'configgeneral',
        title: 'Configuración General',
        type: 'item',
        icon: 'settings',
        url: 'configgeneral',
      },]
    },
  {
    id: 'etapa1',
    title: 'ETAPA 1 - POSTULACIÓN',
    type: 'collapsible',
    children: [
      {
        id: 'registroEvaluadores',
        title: 'Evaluadores',
        type: 'item',
        icon: 'user-plus',
        url: 'evaluadores',
      },
      {
        id: 'revisarPostulaciones',
        title: 'Revisar Postulaciones',
        type: 'item',
        icon: 'user-check',
        url: 'postulaciones',
      },
      {
        id: 'monitoreo',
        title: 'Monitoreo',
        type: 'item',
        icon: 'clipboard',
        url: 'monitoreo-etapa-1',
      }
    ]
  },
  {
    id: 'etapa2',
    title: 'ETAPA 2 - EVALUACIÓN',
    type: 'collapsible',
    children: [
      {
        id: 'evaluacionDesempeno',
        title: 'Iniciar Evaluación',
        type: 'item',
        icon: 'file-text',
        url: 'evaluacion'
      },
      {
        id: 'monitoreo',
        title: 'Monitoreo',
        type: 'item',
        icon: 'clipboard',
        url: 'monitoreo-etapa-2',
      },
      {
        id: 'evaluaciones',
        title: 'Evaluaciones',
        type: 'item',
        icon: 'list',
        url: 'evaluaciones',
      }
    ]
  },
   {
     id: 'etapa3',
     title: 'ETAPA 3 - RESULTADOS',
     type: 'collapsible',
     children: [
       {
        id: 'plandesarrollo',
        title: 'Plan de Desarrollo',
        type: 'item',
        icon: 'book-open',
        url: 'plandesarrollo'
       },
       {
        id: 'miplandesarrollo',
        title: 'Mi Plan de Desarrollo',
        type: 'item',
        icon: 'book-open',
        url: 'miplandesarrollo'
       },
       {
        id: 'resultados',
        title: 'Resultados',
        type: 'item',
        icon: 'check-circle',
        url: 'resultados'
       },
       {
        id: 'nineboxtalent',
        title: 'Nine Box Talent',
        type: 'item',
        icon: 'check-circle',
        url: 'nineBoxTalent'
       },
       {
        id: 'monitoreo etapa 3',
        title: 'Monitoreo',
        type: 'item',
        icon: 'clipboard',
        url: 'monitoreo-etapa-3',
      }
     ]
   },
   {
    id: 'etapa3',
    title: 'ETAPA 3 - REPORTES',
    type: 'collapsible',
    children: [
      {
       id: 'reporte-indi',
       title: 'Reporte Colaboradores',
       type: 'item',
       icon: 'activity',
       url: 'reporteindi'
      },
      {
       id: 'reporte-area',
       title: 'Reporte por Área',
       type: 'item',
       icon: 'activity',
       url: 'reporteArea'
      },
      {
       id: 'reporte-cargo',
       title: 'Reporte Nivel',
       type: 'item',
       icon: 'activity',
       url: 'reporteCargo'
      },
      {
       id: 'reporte-compe',
       title: 'Reporte Competencia',
       type: 'item',
       icon: 'activity',
       url: 'verreportecompe'
      },
      {
       id: 'reporte-indusu',
       title: 'Mi reporte',
       type: 'item',
       icon: 'activity',
       url: 'miReporte'
      }
    ]
  }
]